$(() => {
  const loader = document.querySelector('#loader-redirect')

  if (loader) {
    const page = loader.dataset.approved === 'true' ? 'approved' : 'declined'

    setTimeout(() => {
      window.location = `/application/${page}`
    }, '10000');
  }
});
