/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { customerSignedIn } from '@/shared/helpers/Customer'

export default function Footer({ steps, answers, index }) {
  const filterNonFollowupSteps = (stps) => (
    stps.filter((st) => (
      st.trigger_options.length === 0 && (!customerSignedIn || st.option_type !== 'email')
    )).length
  )

  const currentNonFollowupSteps = filterNonFollowupSteps(steps.slice(0, index))
  const totalNonFollowupSteps = filterNonFollowupSteps(steps)
  const answerIds = Object.keys(answers).map(Number)
  const followupAnswers = steps.filter((st) => (
    answerIds.includes(st.id) && st.trigger_options.length > 0
  )).length
  const current = currentNonFollowupSteps + followupAnswers
  const total = totalNonFollowupSteps + followupAnswers
  const width = `${(current / total) * 100}%`

  return (
    <div className="progress-footer" style={{ width }}>
      {`${current} of ${total}`}
    </div>
  )
}

Footer.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape).isRequired,
}
