import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export default function Portal({ Component, container, ...props }) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded) {
      if (container) {
        container.innerHTML = ''
        setLoaded(true)
      } else if (props.debug && console) {
        console.error(
          'YOU MUST PASS IN A DOM ROOT NODE',
          Component,
          props,
        )
      }
    }
  }, [loaded])

  if (!loaded) { return null }

  return createPortal(<Component {...props} />, container)
}
