import React, { useState, useRef } from 'react'
import uuid from 'react-uuid'
import Tag from '@/admin/Tag'
import { parameterize } from '@/shared/helpers/String'

export default function TagPicker({
  resource_name = null, tag_list_name = null, available_tags = [], current_tags = [], restricted = false,
}) {
  if (resource_name === null || tag_list_name === null) { return '' }

  try {
    available_tags = JSON.parse(atob(available_tags))
    current_tags = JSON.parse(atob(current_tags))
  } catch (e) {
    available_tags = []
    current_tags = []
  }

  const [tagList, setTagList] = useState(current_tags)

  const autoComplete = useRef()

  const availableTagListIncludes = (tag) => available_tags.filter((t) => new RegExp(tag, 'i').test(t) === true).length >= 1

  const handleKeyPress = (e) => {
    if (e.charCode === 13 && autoComplete.current && autoComplete.current.value !== '') {
      e.preventDefault()
      addTag()
    }
  }

  const handleBlur = (e) => {
    addTag()
  }

  const addTag = () => {
    const newTag = parameterize(autoComplete.current.value)

    if (newTag === '') { return }

    if (tagList.includes(newTag)) { return }

    // allow any existing and new tags to be
    // set and not any already autocompleted
    if (!restricted) {
      setTagList([...tagList, newTag])
    }

    // restrict tags to only those already available
    // and not any already autocompleted
    if (restricted && availableTagListIncludes(newTag)) {
      setTagList([...tagList, newTag])
    }

    autoComplete.current.value = ''
  }

  const handleRemoveTag = (tag) => {
    const updatedTags = tagList.filter((t) => new RegExp(tag, 'i').test(t) === false)
    setTagList(updatedTags)
  }

  return (
    <div className="tag-picker">
      <input
        ref={autoComplete}
        type="text"
        name={`${tag_list_name}-autocomplete`}
        id={`${tag_list_name}-autocomplete`}
        className="form-control col-sm-5"
        list={`data-for-${tag_list_name}`}
        placeholder="Type to search..."
        autoComplete="off"
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
      />

      <div className="tag-picker--list my-3">
        { tagList.map((tag, i) => <Tag key={uuid()} text={tag} handleRemoveTag={handleRemoveTag} />) }
      </div>

      <input type="hidden" name={`${resource_name}[${tag_list_name}_list]`} value={tagList} readOnly />
    </div>
  )
}
