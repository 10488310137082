import React, { useEffect, useRef } from 'react'

export default function ValueInput({
  index,
  value,
  shouldFocus,
  handleAdd,
  handleUpdate,
  handleRemove,
  default_key,
}) {
  const inputRef = useRef(null)

  useEffect(() => {
    if (shouldFocus) {
      inputRef.current.focus()
    }
  }, [])

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAdd()
    }
  }

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-11">
        <div className="form-floating">
          <input
            ref={inputRef}
            type="url"
            className="form-control form-control-sm"
            value={value}
            onChange={(e) => handleUpdate(e.target.value, index)}
            onKeyDown={handleEnter}
          />
          <label>{default_key}</label>
        </div>
      </div>
      <div className="col-1">
        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => handleRemove(index)}
        >
          <i className="bi bi-trash text-white" />
        </button>
      </div>
    </div>
  )
}
