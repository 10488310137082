import React from 'react'

export default function Progress({
  id = 0, active = false, loaded = 0, total = 100, classes,
}) {
  return !active ? '' : (
    <div id={`transfer-${id}`} className={`progress ${classes}`}>
      <div
        className="progress-bar bg-primary"
        role="progressbar"
        style={{ width: `${(loaded / total) * 100}%` }}
      />
    </div>
  )
}
