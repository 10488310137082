import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'

export const mealBuilderSlice = createSlice({
  name: 'meal_builder',
  initialState: {
    loaded: false,
    menu: null,
    subscriptionId: null,
    orderId: null,
    order: null,
    orderPaid: false,
    mealPlanId: null,
    mealPlanProduct: null,
    availableMeals: [],
    availableAddons: [],
    currentMeals: [],
    currentAddons: [],
    currentSelect: null,
    currentSelectIndex: null,
    favoriteMealIds: [],
    favoriteAddonIds: [],
    skipThisWeek: null,
    preventSkipThisWeek: false,
    displayPane: false,
    drawerActive: null,
    errors: [],
    isValid: null,
    browseMode: false,
  },
  reducers: {
    setLoaded: (state, action) => {
      state.loaded = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setIsValid: (state, action) => {
      state.isValid = action.payload
    },
    setFavoriteMealIds: (state, action) => {
      state.favoriteMealIds = action.payload
    },
    setFavoriteAddonIds: (state, action) => {
      state.favoriteAddonIds = action.payload
    },
    addFavoriteMeal: (state, action) => {
      state.favoriteMealIds = [action.payload, ...state.favoriteMealIds]
    },
    removeFavoriteMeal: (state, action) => {
      state.favoriteMealIds = state.favoriteMealIds.filter((id) => id !== action.payload)
    },
    addFavoriteAddon: (state, action) => {
      state.favoriteAddonIds = [action.payload, ...state.favoriteAddonIds]
    },
    removeFavoriteAddon: (state, action) => {
      state.favoriteAddonIds = state.favoriteAddonIds.filter((id) => id !== action.payload)
    },
    setDisplayPane: (state, action) => {
      state.displayPane = action.payload
    },
    setAvailableMeals: (state, action) => {
      state.availableMeals = action.payload
    },
    setAvailableAddons: (state, action) => {
      state.availableAddons = action.payload
    },
    setCurrentMeals: (state, action) => {
      state.currentMeals = action.payload
    },
    setCurrentAddons: (state, action) => {
      state.currentAddons = action.payload
    },
    setCurrentSelect: (state, action) => {
      state.currentSelect = action.payload
    },
    setCurrentSelectIndex: (state, action) => {
      state.currentSelectIndex = action.payload
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload
    },
    setOrder: (state, action) => {
      state.order = action.payload
    },
    setOrderPaid: (state, action) => {
      state.orderPaid = action.payload
    },
    setMealPlanId: (state, action) => {
      state.mealPlanId = action.payload
    },
    setMealPlanProduct: (state, action) => {
      state.mealPlanProduct = action.payload
    },
    setMenu: (state, action) => {
      state.menu = action.payload
    },
    setSkipThisWeek: (state, action) => {
      state.skipThisWeek = action.payload
    },
    setPreventSkipThisWeek: (state, action) => {
      state.preventSkipThisWeek = action.payload
    },
    setDrawerActive: (state, action) => {
      state.drawerActive = action.payload
    },
    setBrowseMode: (state, action) => {
      state.browseMode = action.payload
    },
  },
})

export const {
  setIsValid,
  setErrors,
  setLoaded,
  setFavoriteMealIds,
  setFavoriteAddonIds,
  addFavoriteMeal,
  removeFavoriteMeal,
  addFavoriteAddon,
  removeFavoriteAddon,
  setDisplayPane,
  setAvailableMeals,
  setAvailableAddons,
  setCurrentMeals,
  setCurrentSelect,
  setCurrentSelectIndex,
  setMealPlanId,
  setMealPlanProduct,
  setMenu,
  setCurrentAddons,
  setSkipThisWeek,
  setPreventSkipThisWeek,
  setDrawerActive,
  setSubscriptionId,
  setOrderId,
  setOrder,
  setOrderPaid,
  setBrowseMode,
} = mealBuilderSlice.actions

export const selectIsValid = (state) => state.meal_builder.isValid
export const selectErrors = (state) => state.meal_builder.errors
export const selectLoaded = (state) => state.meal_builder.loaded
export const selectFavoriteMealIds = (state) => state.meal_builder.favoriteMealIds
export const selectFavoriteAddonIds = (state) => state.meal_builder.favoriteAddonIds
export const selectDisplayPane = (state) => state.meal_builder.displayPane
export const selectAvailableMeals = (state) => state.meal_builder.availableMeals
export const selectAvailableAddons = (state) => state.meal_builder.availableAddons
export const selectCurrentMeals = (state) => state.meal_builder.currentMeals
export const selectCurrentSelect = (state) => state.meal_builder.currentSelect
export const selectCurrentSelectIndex = (state) => state.meal_builder.currentSelectIndex
export const selectMealPlanId = (state) => state.meal_builder.mealPlanId
export const selectMealPlanProduct = (state) => state.meal_builder.mealPlanProduct
export const selectMenu = (state) => state.meal_builder.menu
export const selectCurrentAddons = (state) => state.meal_builder.currentAddons
export const selectSubscriptionId = (state) => state.meal_builder.subscriptionId
export const selectOrderId = (state) => state.meal_builder.orderId
export const selectOrder = (state) => state.meal_builder.order
export const selectOrderPaid = (state) => state.meal_builder.orderPaid
export const selectSkipThisWeek = (state) => state.meal_builder.skipThisWeek
export const selectPreventSkipThisWeek = (state) => state.meal_builder.preventSkipThisWeek
export const selectDrawerActive = (state) => state.meal_builder.drawerActive
export const selectBrowseMode = (state) => state.meal_builder.browseMode

export const findOrCreateMealPlan = ({ meal_plan_id, location_id, product_sku }) => async (dispatch) => {
  if (!location_id || !product_sku) { return }

  axios({
    method: 'post',
    url: '/api/meal-plans',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      meal_plan_id,
      location_id,
      product_sku,
    },
  })
    .then(({ data }) => {
      dispatch(setMenu(data.menu))
      dispatch(setMealPlanId(data.meal_plan_id))
      dispatch(setAvailableMeals(data.available_meals))
      dispatch(setAvailableAddons(data.available_addons))
      dispatch(setCurrentMeals(data.current_meals))
      dispatch(setCurrentAddons(data.current_addons))
      dispatch(setMealPlanProduct(data.meal_plan_product))
      dispatch(setLoaded(true))
      window.localStorage.setItem('temporary-meal-plan-id', data.meal_plan_id)
    })
}
export const changeProductFavoriteState = (id = null, type = null) => async (dispatch) => {
  axios({
    method: 'post',
    url: '/api/favorites',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      type,
      id,
    },
  })
    .then(({ data }) => {
      if (data.success) {
        if (type === 'meal') {
          if (data.action === 'favorite') {
            dispatch(addFavoriteMeal(id))
          } else if (data.action === 'unfavorite') {
            dispatch(removeFavoriteMeal(id))
          }
        } else if (type === 'addon') {
          if (data.action === 'favorite') {
            dispatch(addFavoriteAddon(id))
          } else if (data.action === 'unfavorite') {
            dispatch(removeFavoriteAddon(id))
          }
        }
      }
    })
}

export const replaceMeal = (mealPlanId = null, currentMeals = [], currentSelectIndex = null, newMealObj = null) => {
  const selections = [...currentMeals]
  selections[currentSelectIndex] = newMealObj

  return async (dispatch) => {
    axios({
      method: 'patch',
      url: '/api/meal-plans/replace-meal',
      headers: { 'X-CSRF-Token': accessToken },
      data: {
        meal_plan_id: mealPlanId,
        meals: selections,
      },
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch(setCurrentMeals(selections))
          dispatch(setDisplayPane(false))
          dispatch(setDrawerActive(false))
        }
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data?.errors)) {
          alert(error?.response?.data?.errors.join(','))
        } else {
          alert(error?.response?.data?.errors)
        }
      })
  }
}

export const updateAddons = (mealPlanId, addons) => async (dispatch, getState) => {
  axios({
    method: 'patch',
    url: '/api/meal-plans/update-addons',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      subscription_id: getState().meal_builder.subscriptionId,
      meal_plan_id: mealPlanId,
      addons,
    },
  })
    .then(({ data }) => {
      if (data.success) {
        dispatch(setCurrentAddons(addons))
      }
    })
    .catch((error) => {
      if (Array.isArray(error?.response?.data?.errors)) {
        alert(error?.response?.data?.errors.join(','))
      } else {
        alert(error?.response?.data?.errors)
      }
    })
}

export const skipThisWeek = (orderId, skipThisWeek) => async (dispatch) => {
  axios({
    method: 'patch',
    url: '/api/meal-plans/skip-this-week',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      order_id: orderId,
      skip_this_week: skipThisWeek,
    },
  })
    .then(({ data }) => {
      if (data.success) {
        dispatch(setSkipThisWeek(skipThisWeek))
      }
    })
}

export default mealBuilderSlice.reducer
