import React, { useEffect } from 'react'

export default function WizardLayout({
  title = '',
  return_url = '#',
  root_url = '#',
  logo_url = '#',
  company_name = '',
  debug = false,
  children,
  onExit = () => {},
}) {
  /**
   * Component mount/unmount
   */
  useEffect(() => {
    if (debug && console) {
      console.log("%c Hagrid: Yer a wizard, 'arry!", 'background:green; color:white;')
    }
  }, [])

  return (
    <>
      <header id="as-app-header" className="mb-3">
        <div className="container">
          <div className="exit">
            <a href={return_url} className="btn btn-tertiary ps-1 pe-3" onClick={onExit}>
              <i className="icon-chevron-left" />
              {' '}
              Exit
            </a>
          </div>

          <h1 className="heading h5 mb-0">{ title }</h1>

          <div className="brand">
            <a href={root_url} onClick={onExit}>
              <img src={logo_url} alt={company_name} />
            </a>
          </div>
        </div>
      </header>

      { children }
    </>
  )
}
