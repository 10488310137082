/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

export default function Checkbox({
  id,
  content,
  type,
  previousAnswer,
}) {
  return (
    <label htmlFor={id} className="d-block mb-4">
      <div className="card card-bordered checkbox-card">
        <div className="card-body d-flex align-items-center">
          {type === 'single_select' ? (
            <input
              id={id}
              className="form-check-input flex-shrink-0"
              type="radio"
              name="single_select"
              value={id}
              defaultChecked={previousAnswer === String(id)}
              required
            />
          ) : (
            <input
              id={id}
              type="checkbox"
              className="form-check-input flex-shrink-0"
              name="multiple_select"
              value={id}
              defaultChecked={previousAnswer?.includes(String(id))}
            />
          )}
          <span className="px-3">{content}</span>
        </div>
      </div>
    </label>
  )
}

Checkbox.propTypes = {
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
