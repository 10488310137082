import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import axios from 'axios'
import { titleize } from '@/shared/helpers/String'
import {
  setComponents,
  setHasBeenModified,
  selectLoaded,
  selectAccessToken,
  selectComponents,
  selectRecordId,
} from '@/admin/components/redux/componentsSlice'
import TextField from '@/admin/components/fields/Text'
import TextArea from '@/admin/components/fields/Textarea'
import RawHTML from '@/admin/components/fields/RawHTML'
import RichTextField from '@/admin/components/fields/RichText'
import ImageField from '@/admin/components/fields/Image'
import LinkField from '@/admin/components/fields/Link'
import DynamicField from '@/admin/components/fields/Dynamic'
import IconField from '@/admin/components/fields/Icon'
import SelectField from '@/admin/components/fields/Select'
import CheckboxField from '@/admin/components/fields/Checkbox'
import SwitchField from '@/admin/components/fields/Switch'
import MultiSelectField from '@/admin/components/fields/MultiSelect'
import FileUpload from '@/admin/components/fields/FileUpload'

export default function Component({
  component = [],
  component_order,
  available_icons,
  testimonials,
  case_studies,
  component_positions,
  background_colors,
  companies,
  doctor_profiles,
  bio_background_colors,
  bio_positions,
  handleDelete,
}) {
  const fieldNameBase = `components[content][${component_order}]`

  const loaded = useSelector(selectLoaded)
  const components = useSelector(selectComponents)
  const accessToken = useSelector(selectAccessToken)
  const recordId = useSelector(selectRecordId)

  const componentTitle = /name/i.test(component.elements?.[0]?.name)
    ? ` - ${titleize(component.elements[0]?.content)}`
    : ''

  const [isCollapsed, setIsCollapsed] = useState(() => {
    switch (true) {
      case components.length === 1:
      case component?.isCollapsed === false:
        return false

      default:
        return true
    }
  })

  const handleComponentDelete = (index) => {
    if (window.confirm('ARE YOU SURE?? All uploaded media associated with this component will be deleted!')) {
      deleteAllAttachedImages()
      handleDelete(index)
    }
  }

  const handleImageDelete = (blob_id) => {
    axios({
      method: 'delete',
      url: `/admin/image/${blob_id}`,
      headers: { 'X-CSRF-Token': accessToken },
    })
      .then(() => {
        if (console) { console.log(`${blob_id} deleted`) }
      })
      .catch((error) => {
        if (console) { console.log(error) }
      })
  }

  /**
   * When the component is unmounted (ie, the user has confirmed
   * that they want to delete it), loop through all attached
   * images and delete/unattach them from the current record
   */
  const deleteAllAttachedImages = () => {
    component.elements.map((element) => {
      if (/file/.test(element.type)) {
        handleImageDelete(element.blob_id)
      }
    })
  }

  return (
    <Draggable
      draggableId={component.id}
      index={component_order}
    >
      { (provided, snapshot) => (

        <div
          className={(
            'component'
            + `${isCollapsed ? ' component--collapsed' : ''}`
            + `${snapshot.isDragging ? ' component--dragging' : ''}`
          ).trim()}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="component--header" onClick={() => setIsCollapsed(!isCollapsed)}>
            <span className="component--delete" onClick={() => handleComponentDelete(component_order)}>
              <i className="bi bi-x" />
            </span>

            <span className={`component--drag-handle ${components.length === 1 ? 'd-none' : ''}`} {...provided.dragHandleProps}>
              <i className="bi bi-grip-horizontal" />
            </span>

            <h5 className="text-dark m-0 ms-2 p-0">
              { titleize(component.component).trim() }
              { componentTitle }
            </h5>
          </div>

          <div className="component--fields">
            <input type="hidden" name={`${fieldNameBase}[order]`} value={component_order} />
            <input type="hidden" name={`${fieldNameBase}[component]`} value={component.component.replace(/_/g, '')} />
            <input type="hidden" name={`${fieldNameBase}[component_title]`} value={componentTitle.replace(/[\s|-]/g, '')} />

            {
              component.fields.map((field, element_order) => {
                const data = component.elements.filter((e) => e.name === field.name)[0]

                switch (true) {
                  case /^(separator){1}$/.test(field.type): return (
                    <hr key={element_order} className="my-5" />
                  )

                  case /^(raw_html){1}$/.test(field.type): return (
                    <RawHTML
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(text_area){1}$/.test(field.type): return (
                    <TextArea
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(text){1}$/.test(field.type): return (
                    <TextField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(rich_text){1}$/.test(field.type): return (
                    <RichTextField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(link){1}$/.test(field.type): return (
                    <LinkField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(image){1}$/.test(field.type): return (
                    <ImageField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                      onDelete={handleImageDelete}
                    />
                  )

                  case /^(dynamic){1}$/.test(field.type): return (
                    <DynamicField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(icon){1}$/.test(field.type): return (
                    <IconField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                      available_icons={available_icons}
                    />
                  )

                  case /^(select){1}$/.test(field.type): return (
                    <SelectField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(checkbox){1}$/.test(field.type): return (
                    <CheckboxField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(switch){1}$/.test(field.type): return (
                    <SwitchField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(multi_select){1}$/.test(field.type): return (
                    <MultiSelectField
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )

                  case /^(file_upload){1}$/.test(field.type): return (
                    <FileUpload
                      key={element_order}
                      definition={field}
                      data={data}
                      component_order={component_order}
                      element_order={element_order}
                    />
                  )
                }
              })
            }
          </div>
        </div>

      )}
    </Draggable>
  )
}
