import React, { useState, useEffect } from 'react'
import uuid from 'react-uuid'
import ValueInput from '@/admin/ValueInput'

export default function ValueStorage({
  resource_name = null,
  field_name = null,
  raw_data = null,
  default_key = 'YOU_FORGOT_TO_PASS_ME_IN',
}) {
  if (resource_name === null || field_name === null || raw_data === null) {
    return ''
  }

  const input_name = `${resource_name}[${field_name}]`
  const [focus, setFocus] = useState(false)
  const [data, setData] = useState([])

  const handleAdd = () => {
    setData([...data, { [default_key]: '' }])
    setFocus(true)
  }

  const handleUpdate = (value, index) => {
    const copy = [...data]
    copy[index] = { [default_key]: value }
    setData([...copy])
  }

  const handleRemove = (index) => {
    const copy = [...data]
    copy.splice(index, 1)
    setData([...copy])
  }

  // prevent empty values from being saved
  const preparedData = () => {
    if (Object.keys(data).length === 0) { return }
    const final_data = [...data].filter((item) => item[default_key].length > 0)
    return JSON.stringify(final_data)
  }

  // initial data setup
  useEffect(() => {
    // JSON parsing may need to happen twice because
    // somtimes Rails can html_safe the content
    try {
      const parsed_data = JSON.parse(JSON.parse(atob(raw_data)))
      if (Object.keys(parsed_data).length > 0) { setData(parsed_data) }
    } catch (e) {
      try {
        const parsed_data = JSON.parse(atob(raw_data))
        if (Object.keys(parsed_data).length > 0) { setData(parsed_data) }
      } catch (e) {
        console.error(e)
        console.log(atob(raw_data))
        setData([])
      }
    }
  }, [])

  return (
    <div className="value-input">
      <input type="hidden" name={input_name} value={preparedData()} />

      {data.map((item, i) => (
        <ValueInput
          index={i}
          value={item[default_key]}
          shouldFocus={focus}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
          default_key={default_key}
          key={uuid()}
        />
      ))}

      {data.length > 0 && <hr />}

      <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={handleAdd}
      >
        <i className="bi bi-plus-circle-fill" />
      </button>
    </div>
  )
}
