import { createSlice } from '@reduxjs/toolkit'
import { accessToken } from '@/shared/helpers/CSRF'

export const componentsSlice = createSlice({
  name: 'components',
  initialState: {
    loaded: false,
    accessToken,
    directUploadUrl: null,
    recordId: null,
    isNewRecord: false,
    hasBeenModified: false,
    availableIcons: null,
    items: [],
  },
  reducers: {
    setLoaded: (state, action) => {
      if (state.loaded === false) {
        state.loaded = true
      }
    },
    setDirectUploadUrl: (state, action) => {
      state.directUploadUrl = action.payload
    },
    setRecordId: (state, action) => {
      state.recordId = action.payload
    },
    setHasBeenModified: (state, action) => {
      if (state.hasBeenModified === false && action.payload !== false) {
        state.hasBeenModified = true
      }
      if (action.payload === false) {
        state.hasBeenModified = false
      }
    },
    setIsNewRecord: (state, action) => {
      state.isNewRecord = action.payload
    },
    setAvailableIcons: (state, action) => {
      state.availableIcons = action.payload
    },
    setComponents: (state, action) => {
      state.items = action.payload
    },
    addComponent: (state, action) => {
      const order = state.items.length > 0
        ? String(Number(state.items[state.items.length - 1].order) + 1)
        : 0

      // force a new copy by using JSON
      const newComponent = JSON.parse(JSON.stringify(action.payload))
      newComponent.id = `component-${order}`
      newComponent.order = order
      newComponent.elements = []
      newComponent.isCollapsed = false

      state.items = [
        ...state.items,
        newComponent,
      ]

      state.hasBeenModified = true
    },
  },
})

export const {
  setLoaded,
  setDirectUploadUrl,
  setRecordId,
  setHasBeenModified,
  setIsNewRecord,
  setAvailableIcons,
  setComponents,
  addComponent,
} = componentsSlice.actions

export const selectLoaded = (state) => state.components.loaded
export const selectAccessToken = (state) => state.components.accessToken
export const selectDirectUploadUrl = (state) => state.components.directUploadUrl
export const selectHasBeenModified = (state) => state.components.hasBeenModified
export const selectIsNewRecord = (state) => state.components.isNewRecord
export const selectRecordId = (state) => state.components.recordId
export const selectAvailableIcons = (state) => state.components.availableIcons
export const selectComponents = (state) => state.components.items

export default componentsSlice.reducer
