import React from 'react'

export default function LoadingOverlay({
  className = '',
  show = false,
  displayContent = true,
  delay = 300,
  children,
}) {
  // const [shown, setShown] = useState(show)

  // useEffect(() => {
  //   if (show) {
  //     window.__delayedLoadingOverlayTimer = window.setTimeout(() => {
  //       setShown(true)
  //     }, delay)
  //   } else {
  //     window.clearTimeout(window.__delayedLoadingOverlayTimer)
  //     setShown(false)
  //   }
  // }, [show])

  return (
    <div className={`loading-overlay ${className} ${show ? 'shown' : ''}`}>
      { displayContent
        && (
        <div className="loading-overlay--content">
          <div className="loading-overlay--icon">
            <div className="lds-roller">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
          { children }
        </div>
        )}
    </div>
  )
}
