import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { setIsMealPlansEligible } from '@/public/redux/signupSlice'

export default function MealAvailabilityForm({
  label = 'Zip Code',
  button_text = 'Check Availability',
  includeSubmitButton = true,
  promptNewsletterSignupWhenUnavailable = true,
  promptSignupWizardWhenAvailable = false,
  signupWizardUrl = '#',
  preventMessageClearing = false,
  displayResult = true,
  children,
  onChecking = () => {},
  onComplete = () => {},
}) {
  const modalTrigger = document.getElementById('prompt-newsletter-signup-when-meal-plans-unavailable-modal-trigger')

  const formMethods = useForm()
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const [available, setAvailable] = useState(null)
  const [area, setArea] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (formData) => {
    onChecking(true)

    if (verified) {
      axios({
        method: 'post',
        url: '/api/meal-plans/check-availability',
        headers: {
          'Content-Type': 'application/json',
          Accepts: 'application/json',
          'X-CSRF-Token': accessToken,
        },
        data: { query: formData.area },
      })
        .then((res) => {
          if (res.data.answer) {
            setAvailable(true)
            dispatch(setIsMealPlansEligible(true))
            window.localStorage.setItem('customer-is-meal-plan-eligible', true)

            if (!promptSignupWizardWhenAvailable) {
              setMessage('Meal delivery is available in your area!')
            }
          } else {
            setAvailable(false)
            dispatch(setIsMealPlansEligible(false))
            window.localStorage.setItem('customer-is-meal-plan-eligible', false)

            setMessage('Sorry, meal delivery is not available in your area...')
            if (promptNewsletterSignupWhenUnavailable) { modalTrigger.click() }
          }
          onComplete(res.data.answer)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          if (!available) {
            formMethods.reset()
          }
          if (!preventMessageClearing) {
            window.setTimeout(() => { setMessage('') }, 1500)
          }
          onChecking(false)
        })
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <div className="mb-3">
          <label
            className={`form-label ${formMethods.formState.errors.area ? 'error' : ''}`}
            htmlFor="meal-availability"
          >
            { label }
          </label>

          <input
            id="meal-availability"
            className={`form-control ${formMethods.formState.errors.area ? 'error' : ''}`}
            type="text"
            disabled={available}
            {...formMethods.register('area', { required: 'This field is required.' })}
          />

          { displayResult && message
                  && (
                  <div className={`mt-2 ${available ? 'text-success' : 'text-danger'}`}>
                    { message }
                  </div>
                  )}

          { formMethods.formState.errors.area
                  && (
                  <div className="form-text error">
                    { formMethods.formState.errors?.area?.message }
                  </div>
                  )}
        </div>

        { includeSubmitButton && (
          <>
            {
                Boolean(available) && promptSignupWizardWhenAvailable ? (
                  <a href={signupWizardUrl} className="btn btn-secondary btn-secondary-filled">
                    Available! — Let's Get Started!
                  </a>
                ) : (
                  <button type="submit" className="btn btn-primary" disabled={!verified}>
                    {button_text}
                  </button>
                )
              }
          </>
        )}

        { children }
      </form>
    </FormProvider>
  )
}
