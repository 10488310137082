import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { titleize } from '@/shared/helpers/String'
import { selectAvailableIcons } from '@/admin/components/redux/componentsSlice'

export default function IconSelector({ baseId = '' }) {
  const available_icons = useSelector(selectAvailableIcons)
  const [parent, setParent] = useState(null)
  const [trix, setTrix] = useState(null)

  const handleClick = (iconName) => {
    if (!trix) { return }

    const icon = new window.Trix.Attachment({
      content: `<i class="icon-${iconName}"></i>`,
      contentType: 'icon',
    })
    trix.editor.insertAttachment(icon)
  }

  useEffect(() => {
    setParent(document.getElementById(baseId))
  }, [])

  useEffect(() => {
    if (!parent) { return }
    setTrix(parent.querySelector('trix-editor'))
  }, [parent])

  return (
    <div id={`${baseId}--react-root`} className="container-fluid p-0">
      <div className="row g-3 mt-0 mb-3">
        {
          available_icons.map((iconName) => (
            <i
              key={uuid()}
              className={`col text-center icon-${iconName} fs-2 cursor-pointer`}
              title={titleize(iconName)}
              onClick={() => handleClick(iconName)}
            />
          ))
        }
      </div>
    </div>
  )
}
