import React, { useState, useEffect } from 'react'
import { titleize } from '@/shared/helpers/String'

export default function MenuBuilder({ data = null, maxMealCapacity = 0, menuLocked = true }) {
  if (data === null) { return '' }

  const saveButton = document.getElementById('save-button')

  const [mealCount, setMealCount] = useState(0)
  const [items, setItems] = useState([])
  const [defaultAmounts, setDefaultAmounts] = useState([])
  const [restrictions, setRestrictions] = useState([])

  const handleChange = (sku, column, value) => {
    const copy = [...items].map((item) => {
      if (item.sku !== sku) { return item }
      const columns = column.split('.')
      let newValue = Number(value)
      newValue = isNaN(newValue) ? 0 : newValue

      // handle object nesting max 2 levels deep
      if (columns.length === 1) { item[columns[0]] = newValue }
      if (columns.length === 2) { item[columns[0]][columns[1]] = newValue }

      return item
    })
    setItems(copy)
  }

  useEffect(() => {
    try {
      setItems(JSON.parse(atob(data)))
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    /**
     * Keep track of the current total
     * number of meals being planned
     */
    if (items.length > 0) {
      const allocations = items.map((item) => (/meal/.test(item.type) ? Number(item.quantity) : 0))
      const count = (allocations.reduce((a, b) => a + b))
      setMealCount(count)
    }

    /**
     * Attempt to discover and set default amount columns
     */
    if (defaultAmounts.length === 0) {
      const amounts = []

      items.forEach((item) => {
        if ('default_amounts' in item) {
          Object.keys(item.default_amounts).forEach((default_amount) => {
            if (amounts.indexOf(default_amount) === -1) {
              amounts.push(default_amount)
            }
          })
        }
      })

      setDefaultAmounts(amounts)
    }

    /**
     * Attempt to discover and set restriction
     * columns if there are none currently set
     */
    if (restrictions.length === 0) {
      const r = []

      items.forEach((item) => {
        if ('restrictions' in item) {
          Object.keys(item.restrictions).forEach((restriction) => {
            if (r.indexOf(restriction) === -1) {
              r.push(restriction)
            }
          })
        }
      })

      setRestrictions(r)
    }
  }, [items])

  return (
    <div className="menu-builder">
      <table className="table table-striped table-hover table-borderless table-responsive align-middle">
        <thead>
          <tr>
            <th>Name</th>
            <th width="100">Type</th>
            <th width="110">Quantity Available</th>
            { defaultAmounts?.map((amount, index) => (
              <th width="160" className="text-capitalize" key={`da${index}`}>
                {titleize(amount)}
              </th>
            ))}
            { restrictions?.map((restriction, index) => (
              <th width="130" className="text-capitalize" key={`rh${index}`}>
                {titleize(restriction)}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {
          items?.map((item, ii) => (
            <tr
              key={`item_${item.sku}_${ii}`}
              className={item.quantity > 0 ? '' : 'fst-italic dimmed'}
            >
              <td>{item.name}</td>
              <td align="left">
                <span className="badge bg-info text-uppercase">{item.type}</span>
              </td>
              <td>
                <input
                  autoFocus={ii === 0}
                  tabIndex={ii + 1}
                  className="form-control"
                  type="number"
                  min="0"
                  placeholder="0"
                  defaultValue={item.quantity}
                  disabled={menuLocked}
                  onChange={(e) => handleChange(item.sku, 'quantity', e.currentTarget.value)}
                />
              </td>
              { item?.default_amounts && Object.keys(item?.default_amounts).map((default_amount, dai) => (
                <td key={`dac_${item.sku}_${dai}`}>
                  <input
                    tabIndex={ii + 1}
                    className="form-control"
                    type="number"
                    min="0"
                    placeholder="0"
                    defaultValue={item.default_amounts[default_amount]}
                    disabled={menuLocked}
                    onChange={(e) => handleChange(item.sku, `default_amounts.${default_amount}`, e.currentTarget.value)}
                  />
                </td>
              ))}
              { item?.restrictions && Object.keys(item?.restrictions).map((restriction, ri) => (
                <td key={`rc_${item.sku}_${ri}`}>
                  <input
                    tabIndex={ii + 1}
                    className="form-control"
                    type="number"
                    min="0"
                    placeholder="0"
                    defaultValue={item.restrictions[restriction]}
                    disabled={menuLocked}
                    onChange={(e) => handleChange(item.sku, `restrictions.${restriction}`, e.currentTarget.value)}
                  />
                </td>
              ))}
            </tr>
          ))
        }
          { items.length === 0
          && <tr><td align="center" colSpan="3">Empty</td></tr>}
        </tbody>
      </table>

      <input type="hidden" name="menu[items]" value={JSON.stringify(items)} />
    </div>
  )
}
