import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'

const initialState = {
  customer: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    password: null,
    password_confirmation: null,
  },

  // the value from devise should override this but in case
  // a value is not passed in, let's set a strong default
  minPasswordLength: 10,
  customerExists: null,

  isMealPlansEligible: null,
  availablePlans: [],
  availableLocations: [],
  deliveryAreas: [],
  amountPerWeek: null,
  productSKU: null,
  pickupOrDelivery: null,
  chosenLocation: null,

  readyToAddToCart: null,
  unrecoverableError: null,
  creatingAccount: false,
  debug: false,
}

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    reset: (state, action) => {
      state = initialState
    },
    setAmountPerWeek: (state, action) => {
      state.amountPerWeek = action.payload
    },
    setAvailableLocations: (state, action) => {
      state.availableLocations = action.payload
    },
    setAvailablePlans: (state, action) => {
      state.availablePlans = action.payload
    },
    setDeliveryArea: (state, action) => {
      state.deliveryAreas = action.payload
    },
    setIsMealPlansEligible: (state, action) => {
      state.isMealPlansEligible = action.payload
    },
    setChosenLocation: (state, action) => {
      state.chosenLocation = action.payload
    },
    setMinPasswordLength: (state, action) => {
      state.minPasswordLength = action.payload
    },
    setPassword: (state, action) => {
      state.customer.password = action.payload.password
      state.customer.password_confirmation = action.payload.password_confirmation
    },
    setPickupOrDelivery: (state, action) => {
      state.pickupOrDelivery = action.payload
    },
    setProductSKU: (state, action) => {
      state.productSKU = action.payload
    },
    setCustomerExists: (state, action) => {
      state.customerExists = action.payload
    },
    setCustomerId: (state, action) => {
      state.customer.id = action.payload
    },
    setCustomerInfo: (state, action) => {
      state.customer.id = action.payload.id
      state.customer.email = action.payload.email
      state.customer.first_name = action.payload.first_name
      state.customer.last_name = action.payload.last_name
    },
    assignCustomerToCart: (state, action) => {
      state.cart.user_id = action.payload
    },
    setReadyToAddToCart: (state, action) => {
      state.readyToAddToCart = action.payload
    },
    setUnrecoverableError: (state, action) => {
      state.unrecoverableError = action.payload
    },
    setCreatingAccount: (state, action) => {
      state.creatingAccount = action.payload
    },
  },
})

export const {
  reset,
  assignCustomerToCart,
  setAmountPerWeek,
  setAvailableLocations,
  setAvailablePlans,
  setChosenLocation,
  setCustomerExists,
  setCustomerId,
  setCustomerInfo,
  setDeliveryArea,
  setIsMealPlansEligible,
  setMinPasswordLength,
  setPassword,
  setPickupOrDelivery,
  setProductSKU,
  setReadyToAddToCart,
  setUnrecoverableError,
  setCreatingAccount,
} = signupSlice.actions

export const selectAmountPerWeek = (state) => state.signup.amountPerWeek
export const selectAvailableLocations = (state) => state.signup.availableLocations
export const selectAvailablePlans = (state) => state.signup.availablePlans
export const selectChosenLocation = (state) => state.signup.chosenLocation
export const selectChosenPlan = (state) => state.signup.availablePlans.filter((plan) => plan.sku === state.signup.productSKU)[0]
export const selectCustomer = (state) => state.signup.customer
export const selectCustomerExists = (state) => state.signup.customerExists
export const selectDeliveryArea = (state) => state.signup.deliveryAreas
export const selectIsMealPlanEligible = (state) => state.signup.isMealPlansEligible
export const selectMinPasswordLength = (state) => state.signup.minPasswordLength
export const selectPickupOrDelivery = (state) => state.signup.pickupOrDelivery
export const selectProductSKU = (state) => state.signup.productSKU
export const selectReadyToAddToCart = (state) => state.signup.readyToAddToCart
export const selectUnrecoverableError = (state) => state.signup.unrecoverableError
export const selectCreatingAccount = (state) => state.signup.creatingAccount

export const checkIfCustomerExists = (email) => async (dispatch) => {
  dispatch(setCustomerExists(null))

  axios({
    method: 'post',
    url: '/api/users/check-existence',
    headers: { 'X-CSRF-Token': accessToken },
    data: { email },
  })
    .then(({ data }) => {
      if (data.answer === true) {
        dispatch(setCustomerExists(true))
      } else {
        dispatch(setCustomerExists(false))
      }
    })
}

export const createCustomer = () => async (dispatch, getState) => {
  if (getState().signup.creatingAccount) { return }

  if (getState().signup.debug && console) { console.log('creating customer') }

  dispatch(setCreatingAccount(true))

  axios({
    method: 'post',
    url: '/api/users',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      user: {
        ...getState().signup.customer,
        location_id: getState().signup.chosenLocation.id,
      },
    },
  })
    .then(({ data }) => {
      dispatch(setCustomerId(data.user_id))
      dispatch(setReadyToAddToCart(true))
      dispatch(setUnrecoverableError(false))
    })
    .catch((error) => {
      if (console) { console.error(error.toJSON()) }
      dispatch(setReadyToAddToCart(false))
      dispatch(setUnrecoverableError(true))
    })
    .then(() => {
      // always do this no matter what
      dispatch(setCreatingAccount(false))
    })
}

export default signupSlice.reducer
