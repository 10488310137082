import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { DirectUpload } from '@rails/activestorage'
import ProgressBar from '@/admin/ProgressBar'
import {
  selectAccessToken,
  selectDirectUploadUrl,
  selectRecordId,
  setHasBeenModified,
} from '@/admin/components/redux/componentsSlice'

export default function FileUpload({
  definition,
  data,
  component_order,
  element_order,
}) {
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const dispatch = useDispatch()
  const recordId = useSelector(selectRecordId)
  const accessToken = useSelector(selectAccessToken)
  const directUploadUrl = useSelector(selectDirectUploadUrl)

  const [error, setError] = useState(false)
  const [fileDownloadUrl, setFileDownloadUrl] = useState('')
  const [fileName, setFileName] = useState('')
  const [blobId, setBlobId] = useState(data?.blob_id || '')
  const [progress, setProgress] = useState({
    loaded: 0,
    total: 100,
    active: false,
  })

  const handleUpload = (e) => {
    const targetFile = e.target.files[0]
    const filename = targetFile.name

    setError(false)

    if (!accessToken || !directUploadUrl || !targetFile || !filename) {
      setError('Unable to upload the file!')
      if (console) { console.error(accessToken, directUploadUrl, targetFile) }
      return
    }

    const upload = new DirectUpload(targetFile, directUploadUrl, {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener('progress', (e) => {
          setProgress({
            ...progress,
            active: e.loaded < e.total,
            loaded: e.loaded,
            total: e.total,
          })
        })
      },
    })

    upload.create((error, blob) => {
      if (error) {
        setError('Unable to upload the file!')
        if (console) { console.error(error) }
      } else {
        axios({
          method: 'post',
          url: '/admin/image',
          headers: { 'X-CSRF-Token': accessToken },
          data: {
            image: {
              attachment_name: definition.attachment_name,
              record_type: definition.record_type,
              record_id: recordId,
              blob_id: blob.id,
            },
          },
        })
          .then((response) => {
            setBlobId(blob.id)
            dispatch(setHasBeenModified())
          })
          .catch((error) => {
            setError('An unknown error occurred!')
            if (console) { console.log(error) }
          })
      }
    })
  }

  const fetchFile = () => {
    axios({
      method: 'get',
      url: `/admin/image/${blobId}`,
    })
      .then((response) => {
        if ('url' in response.data) {
          setFileDownloadUrl(response.data.url)
          setFileName(response.data.file_name)
        }
      })
      .catch((error) => {
        if (console) { console.log(error) }
      })
  }

  /**
   * Initial load
   */
  useEffect(() => {
    if (blobId) { fetchFile() }
  }, [])

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label
        className="col-sm-2 col-form-label"
        htmlFor={`${idBase}_${definition.name}`}
      >
        {definition.label}
      </label>

      <div className="col-sm-10">
        <div className="row g-4">
          <div className="col">
            <div className="row">
              <div className="col-12">
                <input
                  type="hidden"
                  name={`${fieldNameBase}[blob_id]`}
                  value={blobId}
                />
                <input
                  className={`form-control mb-3 ${error ? 'is-invalid' : ''}`}
                  type="file"
                  onChange={(e) => handleUpload(e)}
                />
                <a href={fileDownloadUrl}>{fileName}</a>
                <p className="mt-3">
                  After sign up email:
                  {fileDownloadUrl && fileDownloadUrl.length > 0 ? '"lead-magnet-download-1"' : '"newsletter-sign-up-main"'}
                </p>
                { error && <div className="d-block invalid-feedback">{error}</div> }
                <ProgressBar {...progress} />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
