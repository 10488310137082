import React from 'react'

export default function ApplicationButton({
  product,
  className = 'btn btn-primary',
  children,
  button_text = null,
  product_url = null,
  target = '_self',
}) {
  return (
    <a href={product_url} className={`${className} jsDataLayer`} rel="noopener" target={target} data={{ id: product.id }}>
      { children || button_text || 'Learn More' }
    </a>
  )
}
