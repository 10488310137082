export const titleize = (string) => string.replace(/-|_/g, ' ').split(/(?=[A-Z])/).join(' ')

export const parameterize = (string) => string.toLowerCase().replaceAll(/[^a-z0-9]+/g, '-')

export const pluralize = (count, text) => (parseInt(count) === 1 ? text : `${text}s`)

export const squeeze = (string) => string.split(' ').join('')

export const numberToCurrency = (number) => `$${Number(number).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`

export const formattedNumber = (number) => Number(number).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

// https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
export const toCamelcase = (s) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''))
