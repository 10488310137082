import axios from 'axios'
import { datadogLogs } from '@datadog/browser-logs'

/**
 * Aspire is a platform to track links from content creators promoting products.
 * https://intercom.help/aspireiq_elevate/en/articles/6953281-aspire-javascript-postback-tracking-for-non-shopify-websites
 *
 * Aspire uses Tune under the hood.
 * https://developers.tune.com/javascript-sdk/javascript-methods/
 */

const ASPIRE_URL = 'https://get.aspr.app/aff_lsr'
// The session key is set by a call to `tdl.identify() in the <head>`
const ASPIRE_SESSION_KEY = 'tdl_default'

/**
  * Aspire returns 200 even if the action was unsuccessful. Parse the response
  * with the error message if there is one.
  *
  * A successful response should look like this:
  * {
  *   config: {...}, headers: {...}, ...,
  *   data: 'success=true;'
  * }
  *
  * An unsuccessful response should look like this:
  * {
  *   config: {...}, headers: {...}, ...,
  *   data: 'success=false; err_msg=No Trans. ID provided.;'
  * }
  *
  * If Aspire changes their response, this function is still safe.
  *
  * @param {object} aspireResponse the response object from Aspire
  * @return {Array} [response, errMsg] example: [true, undefined],
  *   [false, 'No Trans. ID provided.']
  */
const parseResponse = (aspireResponse) => {
  if (!aspireResponse || !aspireResponse.data) {
    return [false, undefined]
  }
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [success, errMsg] = aspireResponse
    .data?.split(';')
    .map((s) => s.trim())
    .slice(0, 2)
    .map((s) => s.split('=')[1])

  return [success === 'true', errMsg]
}

/**
 * If the sale came from a creator we have on Aspire, a session was started
 * from a call in the <head> tag.
 *
 * Record that the customer bought something using that creator's link.
 *
 * @param {object} order the Order
 */
export default async function notifyAspire(order) {
  const transactionId = window.sessionStorage.getItem(ASPIRE_SESSION_KEY)
  if (!transactionId) {
    return
  }
  try {
    const response = await axios.get(ASPIRE_URL, {
      params: {
        transaction_id: transactionId,
        adv_unique1: order.id,
        amount: order.payment_total,
      },
    })

    const [success, errorMsg] = parseResponse(response)
    if (success) {
      datadogLogs.logger.info(
        `Sent to Aspire: order ${order.id}`,
        { response },
      )
    } else {
      datadogLogs.logger.error(
        `Aspire error, order ${order.id}: ${errorMsg}`,
        { response },
      )
    }
  } catch (e) {
    datadogLogs.logger.error('Error calling Aspire', { error: e })
  }
}

