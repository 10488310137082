import React from 'react'
import PropTypes from 'prop-types'
import USAMap from 'react-usa-map'

export default function StateLicenseMap({ licensedStates, licensedColor = 'hsl(111,15%,46%)', unlicensedColor = 'hsl(111,15%,66%)' }) {
  return (
    <USAMap
      customize={{
        ...licensedStates.reduce((obj, stateCode) => {
          // eslint-disable-next-line no-param-reassign
          obj[stateCode] = { fill: licensedColor }
          return obj
        }, {}),
      }}
      title="Root Licensed State Map"
      defaultFill={unlicensedColor}
      height="100%"
    />
  )
}

StateLicenseMap.propTypes = {
  licensedStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  licensedColor: PropTypes.string,
  unlicensedColor: PropTypes.string,
}

StateLicenseMap.defaultProps = {
  licensedColor: 'hsl(111,15%,46%)',
  unlicensedColor: 'hsl(111,15%,66%)',
}
