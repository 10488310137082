import './datadog'

window.cleanUpStorage = () => {
  window.localStorage.removeItem('cart')
  window.localStorage.removeItem('cart-temp')
  window.localStorage.removeItem('customer-signed-in')
  window.localStorage.removeItem('customer-timezone-preference')
  window.localStorage.removeItem('customer-is-meal-plan-eligible')
  window.localStorage.removeItem('customer-email')
}

