$(() => {
  const discountCode = document.getElementById('quiz-promo-code')
  const storedDiscountCode = () => localStorage.getItem('discountCode')
  const storedExpiration = () => localStorage.getItem('discountExpiration')
  const minutesRemaining = () => (
    Math.ceil((new Date(storedExpiration()) - new Date()) / (60 * 1000))
  )

  function updateTimer() {
    document.getElementById('timer').innerHTML = minutesRemaining()
  }

  if (discountCode) {
    localStorage.setItem('discountCode', discountCode.innerHTML)
    localStorage.setItem('discountExpiration', discountCode.dataset.promoExpiration)
  }

  if (storedExpiration()) {
    if (new Date() > new Date(storedExpiration())) {
      localStorage.removeItem('discountCode')
      localStorage.removeItem('discountExpiration')
    }

    if (storedDiscountCode()) {
      const banner = document.getElementById('banner')
      const bannerMessage = document.getElementById('banner-message')
      const html = `15% Off and Free Shipping:
                    <span id='quiz-promo-code'>${storedDiscountCode()}</span>
                    <button class='copy-trigger trigger-button' data-target='quiz-promo-code' data-bs-toggle='tooltip' data-bs-title='Copied!'>
                      <i class='bi bi-copy'></i>
                    </button>
                    Code Expires in <span id="timer">${minutesRemaining()}</span> Minutes.`

      bannerMessage.innerHTML = html
      banner.classList.remove('d-none')

      setInterval(() => updateTimer(), 1000)
    }
  }
});
