/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useElements, ExpressCheckoutElement } from '@stripe/react-stripe-js'
import ModalButton from '@/public/ModalButton'

export default function ExpressCheckout({
  submitOrder,
  totals,
  contents,
  terms_policy_modal_id,
}) {
  const [show, setShow] = useState(false);

  const elements = useElements()

  function toCents(dollars) {
    return Math.round(dollars * 100)
  }

  const lineItems = contents?.map((item) => ({
    name: (item.quantity > 1 ? `${item.data.name} x ${item.quantity}` : item.data.name),
    amount: toCents(Number(item.data.original_price)) * item.quantity,
  }))

  if (totals.calculated_amount_saved > 0) {
    lineItems.push({
      name: 'Savings',
      amount: -toCents(totals.calculated_amount_saved),
    })
  }

  if (totals.calculated_shipping_cost > 0) {
    lineItems.push({
      name: 'Shipping',
      amount: toCents(totals.calculated_shipping_cost),
    })
  }

  const onReady = ({ availablePaymentMethods }) => {
    if (availablePaymentMethods) { setShow(true) }
  }

  const onClick = ({ resolve }) => {
    elements.update({ amount: totals.calculated_after_savings * 100 })
    const options = {
      emailRequired: true,
      phoneNumberRequired: true,
      shippingAddressRequired: true,
      allowedShippingCountries: ['US'],
      lineItems,
      shippingRates: [
        {
          id: 'shipping',
          displayName: 'Standard Shipping',
          amount: toCents(totals.calculated_shipping_cost),
          deliveryEstimate: {
            maximum: { unit: 'day', value: 7 },
            minimum: { unit: 'day', value: 5 },
          },
        },
      ],
    };

    resolve(options);
  };

  const onConfirm = async (event) => {
    const billingDetails = event.billingDetails
    const billingAddress = billingDetails.address
    const shippingAddress = event.shippingAddress.address

    const names = billingDetails.name.split(' ')
    const first_name = names.shift()
    const last_name = names.join(' ')
    const email = billingDetails.email
    const phone = billingDetails.phone

    const customer = {
      first_name,
      last_name,
      phone,
      email,
      billing: {
        address_line_1: billingAddress.line1,
        address_line_2: billingAddress.line2,
        city: billingAddress.city,
        state: billingAddress.state,
        zip: billingAddress.postal_code,
        country: billingAddress.country,
      },
      shipping: {
        address_line_1: shippingAddress.line1,
        address_line_2: shippingAddress.line2,
        city: shippingAddress.city,
        state: shippingAddress.region,
        zip: shippingAddress.postal_code,
        country: shippingAddress.country,
      },
    }

    submitOrder(customer)
  }

  return (
    <>
      {show && <p className="mb-2 text-center">Express Checkout</p> }
      <ExpressCheckoutElement onConfirm={onConfirm} onClick={onClick} onReady={onReady} />
      {show && (
        <>
          <div className="mt-2">
            <span>
              By clicking pay you agree to be charged and accept our&nbsp;
            </span>
            <ModalButton
              id={terms_policy_modal_id}
              className="btn btn-link py-0"
              title="Click to view our terms and conditions"
              text="terms and conditions*"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <hr className="split-divider" />
            <span>OR</span>
            <hr className="split-divider" />
          </div>
        </>
      )}
    </>
  )
}

ExpressCheckout.propTypes = {
  submitOrder: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    calculated_shipping_cost: PropTypes.number.isRequired,
    calculated_amount_saved: PropTypes.number.isRequired,
    calculated_after_savings: PropTypes.number.isRequired,
  }).isRequired,
  contents: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    data: PropTypes.shape({
      name: PropTypes.string.isRequired,
      original_price: PropTypes.number.isRequired,
    }),
  })),
  terms_policy_modal_id: PropTypes.string.isRequired,
}

ExpressCheckout.defaultProps = {
  contents: null,
}
