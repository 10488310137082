import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import { customerSignedIn } from '@/shared/helpers/Customer'
import Modal from '@/public/Modal';
import PrivacyModalBody from './PrivacyModalBody'
import Step from './Step'
import Header from './Header'
import Footer from './Footer'

export default function Assessment({ data }) {
  const initialIndex = Number(localStorage.getItem('index')) || 0
  const initialAnswers = JSON.parse(localStorage.getItem('answers')) || {}

  const [index, setIndex] = useState(initialIndex)
  const [answers, setAnswers] = useState(initialAnswers)

  const privacyConfirmed = localStorage.getItem('privacy_confirmed')
  const [showPrivacyModal, setShowPrivacyModal] = useState(!privacyConfirmed)

  const step = data.steps[index]

  let currentAnswers = answers
  const setCurrentAnswers = (answer) => {
    currentAnswers = { ...answers, [step.id]: answer }
    setAnswers(currentAnswers)
    localStorage.setItem('answers', JSON.stringify(currentAnswers))
  }

  const confirmPrivacy = () => {
    localStorage.setItem('privacy_confirmed', true)
    setShowPrivacyModal(false)
  }

  const submit = () => {
    axios({
      method: 'post',
      url: '/api/submissions',
      headers: { 'X-CSRF-Token': accessToken },
      data: { answers: currentAnswers },
    })
      .then(({ data: response }) => {
        window.location = `/quizzes/${response.id}`
      })
  }

  const showStep = (currentIndex) => {
    const currentStep = data.steps[currentIndex]
    const triggerOptions = currentStep.trigger_options

    if (customerSignedIn && data.steps[currentIndex].option_type === 'email') {
      return false
    }

    if (triggerOptions.length === 0) { return true }

    const triggerOptionIds = triggerOptions.map((triggerOption) => triggerOption.id)
    const selectedOptions = Object
      .values(currentAnswers)
      .flat()
      .filter((answer) => Number(answer))
      .map((answer) => Number(answer))

    for (const triggerOptionId of triggerOptionIds) {
      if (selectedOptions.includes(triggerOptionId)) {
        return true
      }
    }

    return false
  }

  const advance = () => {
    for (let currentIndex = index + 1; currentIndex < data.steps.length; currentIndex++) {
      if (showStep(currentIndex)) {
        setIndex(currentIndex)
        localStorage.setItem('index', currentIndex)
        return
      }
    }

    localStorage.removeItem('answers')
    localStorage.removeItem('index')
    submit()
  }

  const back = () => {
    for (let currentIndex = index - 1; currentIndex >= 0; currentIndex--) {
      if (showStep(currentIndex)) {
        setIndex(currentIndex)
        localStorage.setItem('index', currentIndex)
        return
      }
    }

    window.location = '/'
  }

  const handleContinue = (answer) => {
    if (answer) {
      setCurrentAnswers(answer)
    }
    advance()
  }

  return (
    <>
      <Header
        title="Get To The Root Quiz"
        logoUrl={data.logo_url}
        companyName={data.company_name}
        back={back}
      />
      <div className="assessment assessment-bg-dual-color">
        <Step
          key={step.id}
          step={step}
          handleContinue={handleContinue}
          previousAnswer={answers[step.id]}
        />
        <Footer steps={data.steps} step={step} answers={answers} index={index} />
      </div>
      {showPrivacyModal && (
        <Modal
          title="To continue, please read the statement below"
          confirmText="I agree"
          cancelText="I disagree"
          onConfirm={() => confirmPrivacy()}
          onCancel={() => window.location.assign('/policy-decline')}
        >
          <PrivacyModalBody />
        </Modal>
      )}
    </>
  )
}

Assessment.propTypes = {
  data: PropTypes.shape({
    return_url: PropTypes.string.isRequired,
    logo_url: PropTypes.string,
    company_name: PropTypes.string,
    steps: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
}
