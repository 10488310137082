import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberToCurrency } from '@/shared/helpers/String'
import { setQuantity, selectQuantity } from '@/public/redux/productSlice'
import QuantityInput from '@/public/product/QuantityInput'
import AddToCartButton from '@/public/product/AddToCartButton'
import SubscribeButton from '@/public/product/SubscribeButton'
import PolicyLinks from '@/public/product/PolicyLinks'
import PropTypes from 'prop-types'
import NotifyWhenAvailable from './NotifyWhenAvailable'

export default function PriceBlockWithQuantity({
  product,
  returnPolicy,
  shippingPolicy,
  customerIsMember,
  awaitedProductIds,
}) {
  const dispatch = useDispatch()
  const quantity = useSelector(selectQuantity)
  const backOrdered = /back_ordered/i.test(product.status)

  return (
    <div className="card">
      <div className="card-content pt-4">
        {
          !backOrdered && (
            <>
              <p className="subtitle secondary-d50">Quantity</p>

              <QuantityInput
                quantity={quantity}
                handleChange={(newQuantity) => dispatch(setQuantity(newQuantity))}
              />

              <hr />
            </>
          )
        }

        <div className="subtitle bold">
          {
            backOrdered ? (
              <>
                <del>{numberToCurrency(product.price)}</del>
                <span className="text-opacity-50 ms-3 text-secondary small fw-normal">Sold Out</span>
              </>
            ) : (
              numberToCurrency(product.price)
            )
          }
        </div>

        <div className="actions mt-3">
          {
            backOrdered ? (
              <NotifyWhenAvailable product={product} awaitedProductIds={awaitedProductIds} wide />
            ) : (
              <>
                <AddToCartButton
                  product={product}
                  quantity={quantity}
                  className="btn btn-primary btn-block"
                />

                {product.is_subscribable && customerIsMember
                  && (
                    <SubscribeButton product={product} className="btn btn-subscription btn-block mt-3">
                      Subscribe
                    </SubscribeButton>
                  )}

                {product.is_subscribable && !customerIsMember
                  && (
                    <SubscribeButton product={product} className="btn btn-subscription btn-block mt-3">
                      Subscribe & Save
                      {' '}
                      {product.calculated_subscription_savings_percentage}
                    </SubscribeButton>
                  )}
              </>
            )
          }

          <PolicyLinks
            product={product}
            shippingPolicy={shippingPolicy}
            returnPolicy={returnPolicy}
          />
        </div>
      </div>
    </div>
  )
}

PriceBlockWithQuantity.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    price: PropTypes.string,
    is_subscribable: PropTypes.bool,
    calculated_subscription_savings_percentage: PropTypes.string,
  }).isRequired,
  returnPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  shippingPolicy: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  customerIsMember: PropTypes.bool,
  awaitedProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}
PriceBlockWithQuantity.defaultProps = {
  returnPolicy: null,
  shippingPolicy: null,
  customerIsMember: null,
}
