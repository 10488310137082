import React, { useRef, useEffect } from 'react'

export default function ModalButton({
  id,
  className = 'btn btn-secondary',
  hidden = false,
  disabled = false,
  text = 'Open',
  title,
  style,
  children = null,
  click = null,
  onClick,
}) {
  const buttonRef = useRef()

  /**
   * Since the default state is null,
   * if anything other than null is passed in
   * this will trigger the click event thereby
   * acting as a simple show/hide toggle
   */
  useEffect(() => {
    if (click === null) { return }
    buttonRef.current.click()
  }, [click])

  return (
    <button
      ref={buttonRef}
      type="button"
      className={className + (hidden ? ' d-none' : '')}
      data-bs-toggle="modal"
      data-bs-target={`#${id}`}
      disabled={disabled}
      style={style}
      title={title}
    >
      { children || text }
    </button>
  )
}
