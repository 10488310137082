import React from 'react';
import PropTypes from 'prop-types';

export default function Results({ responses }) {
  return (
    <div>
      <h2>Responses</h2>
      <ol>
        {responses.map((response) => (
          <li key={response.id}>
            {response.step.content}
            {'  '}
            {response.option?.content || response.content}
          </li>
        ))}
      </ol>
    </div>
  )
}

Results.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }).isRequired,
    option: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }),
    content: PropTypes.content,
  })).isRequired,
}
