import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { accessToken } from '@/shared/helpers/CSRF'
import { numberToCurrency } from '../../shared/helpers/String'
import AddToCartButton from './AddToCartButton'

function Recommendation({
  recommendation: { id, available_on: availableOn, product },
  removeRecommendation,
}) {
  const current = new Date() > new Date(availableOn)
  const availableOnMessage = current ? 'Available' : 'Available soon'
  const availability = current ? 'success' : 'warning'

  function handleDecline() {
    axios({
      method: 'patch',
      url: `/api/product_recommendations/${id}/decline`,
      headers: { 'X-CSRF-Token': accessToken },
    })
      .then(() => removeRecommendation(id))
  }

  return (
    <div className="mb-4 d-flex align-items-center justify-content-between">
      <div>
        <div>
          <span
            className="me-2"
            data-tooltip-id="service-category-tooltip"
            data-tooltip-content={product.service_category.note}
          >
            {product.name}
            &nbsp;
            <span className="info-icon">&#9432;</span>
          </span>
          <strong>{numberToCurrency(product.price)}</strong>
        </div>
        <div className={`text-${availability} mt-1`}>
          <span className="me-2">{availableOnMessage}</span>
          {availableOn}
        </div>
      </div>
      <div id={`product-button-${id}`}>
        <AddToCartButton product={product} disabled={!current} />
        <button type="button" className="btn btn-secondary ms-2" onClick={handleDecline}>Decline</button>
      </div>
      <Tooltip id="service-category-tooltip" />
    </div>
  )
}

export default function Recommendations({ recommendations }) {
  const [recs, setRecs] = useState(recommendations)

  const removeRecommendation = (id) => {
    setRecs(recs.filter((rec) => rec.id !== id))
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4>Lab Recommendations</h4>
      </div>
      <div className="card-content">
        {recs.length === 0 && (
          <div>No recommendations yet</div>
        )}
        {recs.map((recommendation) => (
          <Recommendation
            key={recommendation.id}
            recommendation={recommendation}
            removeRecommendation={removeRecommendation}
          />
        ))}
      </div>
    </div>
  )
}

Recommendation.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    product: PropTypes.shape({
      price: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      service_category: PropTypes.shape({
        note: PropTypes.string,
      }).isRequired,
    }),
    available_on: PropTypes.string.isRequired,
  }).isRequired,
  removeRecommendation: PropTypes.func.isRequired,
}

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.shape).isRequired,
}
