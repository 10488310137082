export const validationPatterns = {
  url: {
    value: /^(http|https):\/\/[^ "]+$/,
    message: 'Must be a properly formatted url.',
  },

  // valid email address
  email: {
    value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    message: 'Must be a valid email address.',
  },

  phone: {
    value: /^(\d{3}-\d{3}-\d{4})?$/,
    message: 'Must be 10 digits long and formatted as "123-456-7890".',
  },

  zip: {
    value: /^([0-9]{5})+[-{1}]?([0-9]{4})?$/,
    message: '5 or 9 digit (hyphenated) zip codes only.',
  },

  // https://stackoverflow.com/questions/354044/what-is-the-best-u-s-currency-regex
  currency: {
    value: /^([+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?)?$/,
    message: 'Invalid amount',
  },
}
