import React from 'react'
import { useDispatch } from 'react-redux'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { setVerified } from '@/public/redux/recaptchaSlice'

export default function GoogleReCaptchaVerifier() {
  const dispatch = useDispatch()

  return (
    <GoogleReCaptcha onVerify={() => dispatch(setVerified(true))} />
  )
}
