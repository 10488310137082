import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  setLoaded,
  setDirectUploadUrl,
  setHasBeenModified,
  setRecordId,
  setAvailableIcons,
  setComponents,
  selectLoaded,
  selectHasBeenModified,
  selectComponents,
} from '@/admin/components/redux/componentsSlice'
import SaveStatus from '@/admin/components/SaveStatus'
import Component from '@/admin/components/Component'

export default function Components({
  recordId = null,
  data = [],
  availableComponents = [],
  availableIcons = [],
  directUploadUrl = null,
  errors = [],
}) {
  const dispatch = useDispatch()
  const components = useSelector(selectComponents)
  const loaded = useSelector(selectLoaded)
  const hasBeenModified = useSelector(selectHasBeenModified)

  const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const handleDragEnd = (result) => {
    // do nothing if component is
    // dropped outside the list
    if (!result.destination) { return }

    dispatch(setComponents(
      reorderList(
        components,
        result.source.index,
        result.destination.index,
      ),
    ))

    dispatch(setHasBeenModified())
  }

  const handleDelete = (index) => {
    const updatedComponents = components.filter((c, i) => i !== index)
    dispatch(setComponents(updatedComponents))

    if (updatedComponents.length === 0) {
      dispatch(setHasBeenModified(false))
    } else {
      dispatch(setHasBeenModified())
    }
  }

  /**
   * Begin initial load
   */
  useEffect(() => {
    // set component dependency data
    dispatch(setRecordId(recordId))
    dispatch(setDirectUploadUrl(directUploadUrl))
    dispatch(setAvailableIcons(availableIcons))

    /**
     * convert the raw data object into an array and
     * give each component an ID to use with DnD,
     * then mix in its related admin fields.
     *
     * {
     *   component: "FooBar",
     *   order: "1",
     *   elements: {},   - existing elements/data
     *   fields: {},     - available admin fields
     * }
     *
     * note: components without corresponding
     * elements or admin fields will NOT be rendered
     */
    if (data) {
      dispatch(setComponents(
        Object.values(data).map((component, index) => {
          // loop through all known admin fields for a component
          // and find the fields belonging to the current component
          const fieldsForComponent = availableComponents
            .map((ac) => {
              if (component.component === ac.component.replace(/_/g, '')) {
                return ac.fields
              }
            })
            .filter((v) => v !== undefined)

          return {
            id: `component-${index}`,
            ...component,
            ...{ component: component.component.replace(/([\d]*)$/, '_$1') },
            ...{ elements: Object.values(component.elements) },
            ...{ fields: fieldsForComponent[0] },
          }
        })
          .filter((component) => component.elements !== undefined)
          .filter((component) => component.fields !== undefined),
      ))
    }

    if (availableComponents.length === 0 && console) {
      console.warn('There are zero available components.')
    }
  }, [])

  /**
   * Finalize initial load
   */
  useEffect(() => {
    if (!loaded && components.length > 0) {
      dispatch(setLoaded())
    }
  }, [components])

  return availableComponents.length > 0 && (
  <>
    <h4 className="mt-5 d-flex underlined">
      Components
      { hasBeenModified && <SaveStatus classes="mt-2 ms-auto" /> }
    </h4>

    <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
      <Droppable droppableId="droppable">
        { (provided, snapshot) => (
          <div
            id="components"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {
              components.length === 0 ? (
                <p>None yet. Why don't you add some?</p>
              ) : (
                <>
                  { components.map((component, component_order) => (
                    <Component
                      key={component.id}
                      component={component}
                      component_order={component_order}
                      available_icons={availableIcons}
                      handleDelete={handleDelete}
                    />
                  ))}

                  {
                  // This takes the place of the dragged component
                  // and prevents the drop area/list from visually
                  // collapsing during a drag event
                  provided.placeholder
                }
                </>
              )
            }
          </div>
        )}
      </Droppable>
    </DragDropContext>

    {
      components.length === 0 && errors.map((message) => (
        <p key={uuid()} className="alert alert-danger mt-2 text-uppercase fs-tiny">
          {message}
        </p>
      ))
    }
  </>
  )
}
