import React, { useState, useEffect } from 'react'
import KeyValueInput from '@/admin/KeyValueInput'

export default function KeyValueStorage({
  resource_name = null,
  field_name = null,
  raw_data = null,
}) {
  if (resource_name === null || field_name === null || raw_data === null) { return '' }

  const input_name = `${resource_name}[${field_name}]`
  const [focus, setFocus] = useState(false)
  const [data, setData] = useState([])

  const handleAdd = () => {
    setData([...data, ...[{ name: '', value: '' }]])
    setFocus(true)
  }

  const handleUpdate = (key_value_pair, index) => {
    const copy = [...data]
    copy[index] = key_value_pair
    setData([...copy])
  }

  const handleRemove = (index) => {
    const copy = [...data]
    copy.splice(index, 1)
    setData([...copy])
  }

  // prevent empty values from being saved
  const preparedData = () => {
    const final_data = [...data].filter((item) => item?.name?.length > 0 && item?.value?.length > 0)
    return JSON.stringify(final_data)
  }

  // initial data setup
  useEffect(() => {
    // JSON parsing may need to happen twice because
    // somtimes Rails can html_safe the content
    try {
      const parsed_data = JSON.parse(JSON.parse(atob(raw_data)))
      if (Object.keys(parsed_data).length > 0) { setData(parsed_data) }
    } catch (e) {
      try {
        const parsed_data = JSON.parse(atob(raw_data))
        if (Object.keys(parsed_data).length > 0) { setData(parsed_data) }
      } catch (e) {
        console.error(e)
        console.log(atob(raw_data))
        setData([])
      }
    }
  }, [])

  return (
    <div className="key-value-input">
      <input type="hidden" name={input_name} value={preparedData()} />

      {data?.map((item, i) => (
        <KeyValueInput
          key={`kvs-${i}`}
          index={i}
          name={item.name}
          value={item.value}
          shouldFocus={focus}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
        />
      ))}

      {data.length > 0 && <hr />}

      <button type="button" className="btn btn-sm btn-secondary" onClick={handleAdd}>
        <i className="bi bi-plus-circle-fill" />
      </button>
    </div>
  )
}
