import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { DirectUpload } from '@rails/activestorage'
import ProgressBar from '@/admin/ProgressBar'
import { accessToken } from '@/shared/helpers/CSRF'

export default function ProductImages({
  debug = false,
  attachedImages = [],
  directUploadUrl,
  attachmentName,
  recordType,
  recordId,
}) {
  const ACCEPTABLE_IMAGE_MIME_TYPES = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/tiff',
    'image/webp',
  ]

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ accept: ACCEPTABLE_IMAGE_MIME_TYPES.join(', ') })

  const [images, setImages] = useState(attachedImages)
  const [newImage, setNewImage] = useState(null)
  const [progressBars, setProgressBars] = useState([])
  const [updatedProgressBar, setUpdatedProgressBar] = useState(null)
  const [error, setError] = useState(false)
  const [blobId, setBlobId] = useState('')
  const [activeClass, setActiveClass] = useState('')

  const handleUpload = (targetFile, index) => new Promise((resolve, reject) => {
    const filename = targetFile.name

    setError(false)

    if (!accessToken || !directUploadUrl || !targetFile || !filename) {
      setError('Unable to upload the file!')
      if (console) { console.error(accessToken, directUploadUrl, targetFile) }
      return reject()
    }

    if ('type' in targetFile && ACCEPTABLE_IMAGE_MIME_TYPES.indexOf(targetFile.type) === -1) {
      setError('Only images are allowed.')
      return reject()
    }

    const upload = new DirectUpload(targetFile, directUploadUrl, {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener('progress', (e) => {
          setUpdatedProgressBar({
            ...progressBars[index],
            id: index,
            active: e.loaded < e.total,
            loaded: e.loaded,
            total: e.total,
          })
        })
      },
    })

    upload.create((error, blob) => {
      if (error) {
        setError('Unable to upload the file!')
        if (console) { console.error(error) }
        reject()
      } else {
        // attach the new directly uploaded
        // image to the current record
        axios({
          method: 'post',
          url: '/admin/image',
          headers: { 'X-CSRF-Token': accessToken },
          data: {
            image: {
              attachment_name: attachmentName,
              record_type: recordType,
              record_id: recordId,
              blob_id: blob.id,
            },
          },
        })
          .then((response) => {
            setNewImage({
              blob_id: blob.id,
              url: `${window.location.origin}/rails/active_storage/blobs/${blob.signed_id}/${filename}`,
            })
            if (console && debug) { console.log(`${filename} uploaded (${blob_id})`) }
            resolve()
          })
          .catch((error) => {
            setError('An unknown error occurred!')
            if (console) { console.log(error) }
            reject()
          })
      }
    })
  })

  const handleDelete = (blob_id) => {
    if (window.confirm('Are you sure?')) {
      axios({
        method: 'delete',
        url: `/admin/image/${blob_id}`,
        headers: { 'X-CSRF-Token': accessToken },
      })
        .then(() => {
          setImages(images.filter((image) => image.blob_id !== blob_id))
          if (console && debug) { console.log(`${blob_id} deleted`) }
        })
        .catch((error) => {
          if (console) { console.log(error) }
        })
    }
  }

  /**
   * Upload file(s) upon
   * acceptance of the dropzone
   */
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach(async (file, index) => {
        await handleUpload(file, index)
      })
    }
  }, [acceptedFiles])

  /**
   * Update the image grid with
   * a new uploaded image
   */
  useEffect(() => {
    if (newImage) {
      const newImages = [...images]
      newImages.unshift(newImage)
      setNewImage(null)
      setImages(newImages)
    }
  }, [newImage])

  /**
   * Update the progress bars object
   * whenever a single progress bar updates
   */
  useEffect(() => {
    if (updatedProgressBar) {
      const updatedProgressBars = [...progressBars]
      updatedProgressBars[updatedProgressBar.id] = updatedProgressBar
      setUpdatedProgressBar(null)
      setProgressBars(updatedProgressBars)
    }
  }, [updatedProgressBar])

  /**
   * Set CSS active class on the dropzone
   * when dropping a file into it
   */
  useEffect(() => {
    setActiveClass(isDragActive ? 'drag-active' : '')
  }, [isDragActive])

  return (
    <>
      <div className="dropzone-container">
        <div {...getRootProps({ className: ['dropzone', activeClass].join(' ') })}>
          <input {...getInputProps()} />
          <p className="m-0 text-muted">Drop files here or click to select files to upload.</p>
        </div>

        { error && <div className="d-block invalid-feedback">{error}</div> }

        { progressBars.map((progress, index) => <ProgressBar key={`transfer-${index}`} classes="mt-3" {...progress} />)}
      </div>

      {
        images.length > 0 && (
        <>
          <h4 className="mt-5 underlined">Images</h4>

          <div className="row row-cols-6 row-cols-sm-3 py-2 g-4">
            {
                images.map((image) => (
                  <div className="col" key={image.blob_id}>
                    <div className="position-relative w-100">
                      <button
                        type="button"
                        className="btn btn-danger position-absolute top-0 start-100 translate-middle badge rounded-pill p-0"
                        onClick={() => handleDelete(image.blob_id)}
                      >
                        <i className="bi bi-x fs-5" />
                      </button>
                      <img src={image.url} className="w-100 rounded" />
                    </div>
                  </div>
                ))
            }
          </div>
        </>
        )
      }
    </>
  )
}
