import { createSlice } from '@reduxjs/toolkit'

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState: {
    errors: [],
    NeXTSTEP: null,
    currentStep: null,
    flow: null,
  },
  reducers: {
    loadStep: (state, action) => {
      const step = state.flow.filter((step) => step.id === action.payload)
      if (step.length === 1) { state.currentStep = step[0] }
    },
    resetErrors: (state, action) => {
      state.errors = []
    },
    setErrors: (state, action) => {
      let updatedErrors = [...state.errors]

      if (!state.errors.includes(action.payload)) {
        if (Array.isArray(action.payload)) {
          updatedErrors = action.payload
        } else {
          updatedErrors.push(action.payload)
        }
      }

      state.errors = updatedErrors
    },
    setFlow: (state, action) => {
      state.flow = action.payload
    },
    setNeXTSTEP: (state, action) => {
      state.NeXTSTEP = action.payload
    },
  },
})

export const {
  loadStep,
  resetErrors,
  setErrors,
  setCurrentStep,
  setNeXTSTEP,
  setFlow,
} = wizardSlice.actions

export const selectErrors = (state) => state.wizard.errors
export const selectCurrentStep = (state) => state.wizard.currentStep
export const selectNeXTSTEP = (state) => state.wizard.NeXTSTEP

export default wizardSlice.reducer
