import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select';
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { accessToken } from '@/shared/helpers/CSRF'
import { validationPatterns } from '@/shared/helpers/Errors'
import { datadogLogs } from '@datadog/browser-logs'

export default function NewsletterForm({ downloadUrl = '', downloadName = '', buttonText = '' }) {
  const verified = useSelector(selectVerified)
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors },
  } = useForm()
  const [message, setMessage] = useState('')

  const submit = async ({ interest, ...data }) => {
    const sendingData = {
      interest: interest ? interest.map((i) => i.value) : [],
      ...data,
      download_url: downloadUrl,
      download_name: downloadName,
    }
    try {
      const response = await axios({
        method: 'post',
        url: '/api/newsletter_signups',
        headers: {
          'Content-Type': 'application/json',
          Accepts: 'application/json',
          'X-CSRF-Token': accessToken,
        },
        data: sendingData,
      })
      if (!response.data.success) {
        datadogLogs.logger.error('Newsletter subscribe form error', response.data.last_error)
        setMessage('There was an error subscribing.')
        return
      }
      setMessage(downloadUrl && downloadUrl.length > 0 ? 'Thank you! Check your email for your download link.' : 'Thank you for signing up!')
      reset({
        name: '',
        email: '',
      }, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: true,
      })
      window.setTimeout(() => {
        document.getElementById('newsletter-signup-modal')?.click()
        setMessage('')
      }, 6000)
    } catch (error) {
      setMessage('There was an error subscribing.')
      datadogLogs.logger.error('Newsletter subscribe form error', error)
    }
  }

  const interests = [
    { value: 'pcos', label: 'PCOS' },
    { value: 'thyroid', label: 'Thyroid' },
    { value: 'gut-health', label: 'Gut Health' },
    { value: 'metabolism', label: 'Metabolism' },
    { value: 'health-optimization', label: 'Health Optimization' },
  ]
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-sm-6">
          <label
            htmlFor="newsletter-signup-name"
            className="form-label"
          >
            First Name
          </label>
          <input
            id="newsletter-signup-name"
            className={`form-control ${errors?.name ? 'error' : ''}`}
            {...register('name', { required: 'A name is required' })}
          />
          {errors?.name
            && <em className="text-danger"><small>{errors.name.message}</small></em>}
        </div>

        <div className="col-sm-6">
          <label
            htmlFor="newsletter-signup-email"
            className="form-label"
          >
            Email Address
          </label>
          <input
            id="newsletter-signup-email"
            className={`form-control ${errors?.email_address ? 'error' : ''}`}
            type="email"
            {...register('email_address', { required: 'An email address is required', pattern: validationPatterns.email })}
          />
          {errors?.email_address
            && <em className="text-danger"><small>{errors.email_address.message}</small></em>}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-6">
          <label
            className="form-label"
          >
            I'm interested in...
          </label>
          <Controller
            control={control}
            name="interest"
            render={({
              field: {
                onChange, onBlur, value, name, ref,
              },
            }) => (
              <Select
                id="combobox"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    minHeight: '46px',
                    borderRadius: '0.5rem',
                  }),
                }}
                options={interests}
                isMulti
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                ref={ref}
              />
            )}
          />
        </div>
        <div className="col-sm-6 news-letter-form">
          <p className="mt-3 mb-n1">{message}</p>
          <button
            type="submit"
            className="btn btn-secondary btn-secondary-filled my-3"
            disabled={!verified}
          >
            {buttonText || 'Subscribe To Our Newsletter'}
          </button>
        </div>
      </div>
    </form>
  )
}
