import React from 'react'

export default function PrivacyModalBody() {
  return (
    <div>
      <p>
        In completing this quiz, you will be asked to provide personal information,
        including certain health information. Root processes this personal information
        in order to provide you with a recommendations. Please review our &nbsp;
        <a target="_blank" href="/policies/privacy-policy">Privacy Statement</a>
        &nbsp; for more information.
      </p>
      <p>
        By clicking &quot;I agree&quot; and completing the quiz,
        you consent to us processing your health information for the purposes described above.
        You can withdraw your consent at any time, as indicated in our &nbsp;
        <a target="_blank" href="/policies/privacy-policy">Privacy Statement</a>
        .&nbsp; If you do not want us to process this information,
        please do not proceed with the quiz.
      </p>
    </div>
  )
}
