import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setIsNewRecord, selectHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function SaveStatus({ isNewRecord = false, classes = '' }) {
  const dispatch = useDispatch()
  const hasBeenModified = useSelector(selectHasBeenModified)

  useEffect(() => {
    dispatch(setIsNewRecord(isNewRecord))
  }, [])

  return (
    <>
      { hasBeenModified
      && (
      <span className={`badge bg-danger fw-normal fs-tiny ${classes}`}>
        <i className="bi bi-exclamation-triangle-fill" />
        {' '}
        Unsaved Changes
      </span>
      )}
    </>
  )
}
