import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default function Modal({
  children,
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
}) {
  return ReactDOM.createPortal(
    <>
      <div className="public-modal-shadow" />
      <div className="public-modal">
        <div className="public-modal-header">
          <span className="fs-4">{title}</span>
        </div>
        <div className="public-modal-body">
          { children }
        </div>
        <div className="public-modal-footer">
          {onCancel && (
            <button type="button" className="btn btn-secondary me-3" onClick={onCancel}>{cancelText}</button>
          )}
          <button type="button" className="btn btn-primary" onClick={onConfirm}>
            {confirmText}
          </button>
        </div>
      </div>
    </>,
    document.body,
  );
}

Modal.proptypes = ({
  children: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
})
