import React from 'react'

export default function CartLedgerItem({
  label, value, discountCode = null, className = '', children,
}) {
  return (
    <div className={`cart-total ${className}`}>
      <div className="cart-total-label">
        { label }

        { discountCode
            && (
            <span className="ms-2 font-monospace fw-normal">
              (
              {discountCode}
              )
            </span>
            )}
      </div>

      <div className="dots" />

      <div className="cost">
        { children || value }
      </div>
    </div>
  )
}
