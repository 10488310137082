import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import store from '@/public/redux/store'
import utils from '@/shared/WizardUtils'
import Errors from '@/public/wizards/Errors'
import Button from '@/public/wizards/Button'
import {
  loadStep, resetErrors, setErrors, selectErrors, selectCurrentStep,
} from '@/public/redux/wizardSlice'

export default function Question({
  globalWizardSpecificActions = {}, debug, onSuccess = () => {}, ...props
}) {
  const {
    question, instruction, inputType, choices, actions,
  } = props.data

  const formRef = useRef()
  const dispatch = useDispatch()
  const formMethods = useFormContext()
  const errors = useSelector(selectErrors)
  const currentStep = useSelector(selectCurrentStep)

  const [delayedActions, setDelayedActions] = useState([])

  let dynamic_choices = choices
  if (typeof choices === 'function') {
    dynamic_choices = choices(store.getState())
  }

  /**
   * Handle the selection of an answer and
   * execute any defined stepActions
   */
  const handleAnswerSelection = (e, actions) => {
    if (errors.length > 0) { dispatch(resetErrors()) }

    if (/radio/i.test(inputType)) {
      utils.executeActions(actions, executeActions, debug)
    }

    if (/checkbox/i.test(inputType)) {
      let updatedDelayedActions = [...delayedActions]

      // first, find the actions to execute right away
      const immediateActions = [...actions].filter((action) => /^[uncheck]/i.test(action))

      if (debug && console) {
        console.log('immediate actions')
        console.log(immediateActions)
      }

      // and execute them...
      utils.executeActions(immediateActions, executeActions, debug)

      // then, handle adding or removing any delayed actions

      // ADD
      // onchange + checked = it was unchecked and is now checked
      if (e.target.checked) {
        const newDelayedActions = [...actions].filter((action) => /^[uncheck]/i.test(action) === false)
        updatedDelayedActions = [...updatedDelayedActions, ...newDelayedActions]

      // REMOVE
      // onchange + NOT checked = it was checked and is now unchecked
      } else {
        actions.forEach((action) => {
          // in case there are duplicates, can't use filter(), must use splice() instead,
          // otherwise it will incorrectly filter out ALL of the same action
          updatedDelayedActions.splice(updatedDelayedActions.indexOf(action), 1)
        })
      }

      // finally update redux
      setDelayedActions(updatedDelayedActions)
    }
  }

  useEffect(() => {
    if (debug && console) {
      console.log('delayed actions')
      console.log(delayedActions)
    }
  }, [delayedActions])

  /**
   * Go to the next question
   */
  const handleAction = (data) => {
    const response = {
      step_id: currentStep.id,
      question,
      answers: data.answers || [],
    }

    if (response.answers.length > 0) {
      dispatch(resetErrors())

      if (delayedActions.length > 0) {
        // make sure all of the delayed actions are unique once
        // we're finally ready to add them to the assessment record
        let uniqueDelayedActions = [...delayedActions]
        uniqueDelayedActions = uniqueDelayedActions.filter((action, index) => uniqueDelayedActions.indexOf(action) === index)

        utils.executeActions([...actions, ...uniqueDelayedActions], executeActions, debug)
      } else {
        utils.executeActions(actions, executeActions, debug)
      }

      onSuccess(response)
    } else {
      dispatch(setErrors(
        /checkbox/i.test(inputType)
          ? 'You must select at least one answer to continue.'
          : 'You must make a choice to continue',
      ))
    }
  }

  const executeActions = {
    ...globalWizardSpecificActions,

    uncheck: (checkbox_value) => {
      const elements = formRef.current.querySelectorAll(`input[value="${checkbox_value}"]`)
      elements.forEach((checkbox) => {
        checkbox.checked = false
      })
    },
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-10 col-lg-8 pt-5">

          <form ref={formRef} onSubmit={formMethods.handleSubmit(handleAction)}>
            <h2 className="sm mt-5 mb-5">
              { question }

              { instruction
                        && <span className="d-block fs-5">{ instruction }</span>}
            </h2>

            {
                      dynamic_choices && dynamic_choices.map(({ text, actions }) => {
                        const registration = formMethods.register('answers', { shouldUnregister: true })
                        return (
                          <label
                            key={text}
                            className="card-as-radio card-as-radio-sm oops-all-checkboxes mb-4"
                          >
                            <input
                              type={inputType}
                              value={text}
                              {...registration}
                              onChange={(e) => {
                                registration.onChange(e)
                                window.setTimeout(() => { handleAnswerSelection(e, actions) }, 200)
                              }}
                            />

                            <div className="card card-bordered">
                              <div className="card-content">
                                <div>{ text }</div>
                              </div>
                            </div>
                          </label>
                        )
                      })
                    }

            <Errors />

            { actions && <Button /> }
          </form>

        </div>
      </div>
    </div>
  )
}
