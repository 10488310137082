/* eslint-disable no-param-reassign */
// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.min.css'

$(() => {
  if (!document.querySelector('.swiper') || window.innerWidth < 768) {
    return
  }

  // eslint-disable-next-line no-new
  new Swiper('.swiper', {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
})
