import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useStripe } from '@stripe/react-stripe-js'
import { numberToCurrency } from '@/shared/helpers/String'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  removeFromCart,
  updateQuantityInCart,
  selectDiscountCode,
  selectContents,
  selectStatus,
  selectError,
  selectLoading,
  selectTotals,
  selectUUID,
  selectConditionals,
} from '@/public/redux/cartSlice'
import CartItem from '@/public/cart/CartItem'
import CartLedger from '@/public/cart/CartLedger'
import ModalButton from '@/public/ModalButton'
import LoadingOverlay from '@/public/LoadingOverlay'
import DiscountCode from '@/public/cart/DiscountCode'

export default function Cart({
  terms_policy_modal_id,
  activeDiscountCodePresent,
}) {
  const { register, formState: { errors } } = useFormContext()

  const stripe = useStripe()
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const paymentError = useSelector(selectError)
  const loading = useSelector(selectLoading)
  const cartStatus = useSelector(selectStatus)
  const uuid = useSelector(selectUUID)
  const contents = useSelector(selectContents)
  const discount = useSelector(selectDiscountCode)
  const totals = useSelector(selectTotals)
  const conditionals = useSelector(selectConditionals)

  const [shouldShowLoading, setShouldShowLoading] = useState(null)
  const [buttonText, setButtonText] = useState('')

  const handleQuantityChange = (item, quantity) => {
    dispatch(updateQuantityInCart({ item, quantity }))
  }

  const handleRemoveItem = (event, item) => {
    event.stopPropagation()
    dispatch(removeFromCart(item))
  }

  useEffect(() => {
    setShouldShowLoading(loading || /updating/i.test(cartStatus))

    if (loading) {
      setButtonText('Please Wait')
    } else if (conditionals.contains_subscriptions) {
      setButtonText('Continue Checkout')
    } else {
      setButtonText('Place Order')
    }
  }, [cartStatus, loading, conditionals])

  return (
    <div className="col-lg-6 col-xl-5 cart-col">
      <h2 className="underline mb-5">Cart</h2>

      <div className="cart-pane cart-checkout loading-overlay--container">
        <LoadingOverlay show={shouldShowLoading} className="tan" />

        <div className="cart-list pe-0">
          { contents?.map((item, index) => (
            <CartItem
              key={`cart-item-${index}`}
              data={item.data}
              quantity={item.quantity}
              quantifiable={item.quantifiable}
              subscription={item.subscription}
              pickupOrDelivery={item?.pickup_or_delivery}
              handleQuantityChange={(quantity) => handleQuantityChange(item, quantity)}
              handleRemove={(event) => handleRemoveItem(event, item)}
            />
          ))}
        </div>

        <CartLedger inCheckout />

        {activeDiscountCodePresent && <DiscountCode />}
      </div>

      <div className="cart-footer pe-0">
        <p>
          By clicking pay you agree to be charged and accept our&nbsp;
          <ModalButton
            id={terms_policy_modal_id}
            className="btn btn-link"
            title="Click to view our terms and conditions"
            text="terms and conditions*"
          />
        </p>

        <div className="cart-footer-actions">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={!!(!verified || loading || !stripe)}
          >
            {buttonText}
          </button>
        </div>

        { paymentError
        && <p className="text-danger text-center mt-5 mb-n5 px-5">{paymentError}</p>}
      </div>
    </div>
  )
}
