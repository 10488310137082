import React from 'react'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function TextField({
  definition, data, component_order, element_order,
}) {
  const dispatch = useDispatch()
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label
        className="col-sm-2 col-form-label"
        htmlFor={`${idBase}_${definition.name}`}
      >
        {definition.label}
      </label>

      <div className="col-sm-10">
        <input
          type="text"
          id={`${idBase}_${definition.name}`}
          name={`${fieldNameBase}[content]`}
          className="form-control"
          defaultValue={data?.content || ''}
          onChange={() => dispatch(setHasBeenModified())}
        />
      </div>
    </div>
  )
}
