import React, { useState, useEffect, useRef } from 'react'

export default function KeyValueInput({
  index, name, value, shouldFocus, handleAdd, handleUpdate, handleRemove,
}) {
  const inputRef = useRef()

  const [_name, setName] = useState(name)
  const [_value, setValue] = useState(value)

  useEffect(() => {
    if (
      (_name !== name || _value !== value)
      && (_name !== '' && _value !== '')
    ) {
      handleUpdate({ name: _name, value: _value }, index)
    }
  }, [_name, _value])

  useEffect(() => {
    if (shouldFocus) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    setName(name)
  }, [name])

  useEffect(() => {
    setValue(value)
  }, [value])

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (e.target.name === 'name') {
        const { form } = e.target
        const index = Array.prototype.indexOf.call(form, e.target)
        form.elements[index + 1].focus()
      } else {
        handleAdd()
      }
    }
  }

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-5">
        <div className="form-floating">
          <input
            ref={inputRef}
            type="text"
            className="form-control form-control-sm"
            placeholder="name"
            value={_name}
            name={`name-${index}`}
            onChange={(e) => setName(e.currentTarget.value)}
            onKeyDown={handleEnter}
          />
          <label>Name</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="value"
            value={_value}
            name="value"
            name={`value-${index}`}
            onChange={(e) => setValue(e.currentTarget.value)}
            onKeyDown={handleEnter}
          />
          <label>Value</label>
        </div>
      </div>
      <div className="col-1">
        <button type="button" className="btn btn-sm btn-danger" onClick={() => handleRemove(index)}>
          <i className="bi bi-trash text-white" />
        </button>
      </div>
    </div>
  )
}
