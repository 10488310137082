$(() => {
  if (!document.querySelector('.wte')) {
    return
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      entry.target.classList.toggle('in-viewport', entry.isIntersecting)
    })
  })

  document.querySelectorAll('.wte-step').forEach((el) => {
    observer.observe(el, { threshold: 1 })
  })

  document.querySelectorAll('.wte-month > h4').forEach((el) => {
    observer.observe(el, { threshold: 1 })
  })
})
