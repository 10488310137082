import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  fetchCart,
  fetchDiscountCode,
  resetDiscountMessage,
  selectDiscountCode,
  selectLoading,
  selectPaymentRequestOpen,
} from '@/public/redux/cartSlice'

export default function DiscountCode({ delayToClear = 3000 }) {
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const discount = useSelector(selectDiscountCode)
  const loading = useSelector(selectLoading)
  const paymentRequestOpen = useSelector(selectPaymentRequestOpen)

  const [timer, setTimer] = useState(null)
  const [code, setCode] = useState('')

  const handleDiscountAttempt = (e) => {
    e.preventDefault()

    if (code !== '') {
      dispatch(fetchDiscountCode(code))
    }
  }

  /**
   * React to a discount check attempt
   *
   * SUCCESS: refresh the cart contents
   * FAIL: auto-clear the input/message after a short delay
   */
  useEffect(() => {
    if (!discount?.message) { return }

    window.setTimeout(() => {
      setCode('')
      dispatch(resetDiscountMessage())
    }, delayToClear)
  }, [discount?.message])

  return discount?.code ? '' : (
    <div className="cart-discount">
      <div>
        <label htmlFor="discountCode" className="form-label">
          Discount Code
        </label>

        <div className="input-group">
          <input
            id="discountCode"
            className={`form-control text-uppercase fs-6 font-monospace ${(discount?.code ? 'disabled' : '')}`}
            type="text"
            value={code}
            disabled={!verified || discount?.code || discount?.message}
            onChange={(e) => setCode(e.target.value)}
          />

          <button
            className={`btn btn-primary ${(discount?.code ? 'disabled' : '')}`}
            disabled={!verified || discount?.code || discount?.message || paymentRequestOpen}
            onClick={handleDiscountAttempt}
          >
            {
              loading ? 'CHECKING'
                : discount?.code
                  ? 'NICE!'
                  : 'CHECK'
            }
          </button>
        </div>

        { discount?.message
          && (
          <p
            className={`px-3 mt-0 mb-n4 ${discount?.code ? 'text-success' : 'text-danger'}`}
            style={{ fontSize: '88%' }}
          >
            { discount.message }
          </p>
          )}
      </div>
    </div>
  )
}
