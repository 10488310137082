import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const DATADOG_CLIENT_TOKEN = 'pubc1b9fb3cf47886395e899f78fe952868'
const DATADOG_SITE = 'us3.datadoghq.com'
const DATADOG_SERVICE = 'storefront-ui'

datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: DATADOG_SERVICE,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: process.env.DD_ENV,
})

if (process.env.RAILS_ENV === 'production') {
  datadogRum.init({
    applicationId: 'a7516dea-48db-48a9-bbd9-3b9640b14b3c',
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: process.env.DD_ENV,
    sessionSampleRate: process.env.DD_TRACE_SAMPLE_RATE,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}
