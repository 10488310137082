import { configureStore } from '@reduxjs/toolkit'
import assessmentReducer from '@/public/redux/assessmentSlice'
import cartReducer from '@/public/redux/cartSlice'
import mealBuilderReducer from '@/public/redux/mealBuilderSlice'
import modalReducer from '@/public/redux/modalSlice'
import productReducer from '@/public/redux/productSlice'
import recaptchaReducer from '@/public/redux/recaptchaSlice'
import signupReducer from '@/public/redux/signupSlice'
import wizardReducer from '@/public/redux/wizardSlice'

export default configureStore({
  reducer: {
    assessment: assessmentReducer,
    cart: cartReducer,
    meal_builder: mealBuilderReducer,
    modal: modalReducer,
    product: productReducer,
    recaptcha: recaptchaReducer,
    signup: signupReducer,
    wizard: wizardReducer,
  },
  // adding in the middleware to account for dynamic questions in question_flow.js
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['wizard/setFlow', 'wizard/loadStep', 'assessment/setMedicalTopics', 'assessment/setRootCauses', 'assessment/setOptions',
        'wizard/resetErrors', 'recaptcha/setVerified', 'assessment/setQuestionResponses',
        'assessment/setRecommendedProducts', 'assessment/setQuestionsToAsk', 'assessment/setPoints', 'assessment/setVisitorEmail'],

    },
  }),
})
