import React from 'react'
import PropTypes from 'prop-types';
import STATES from '../../constants/states'

export default function StateDropdown({ stepId }) {
  return (
    <div>
      <label htmlFor={stepId} className="form-label">State</label>
      <select id={stepId} name="text" className="form-control form-select" required>
        <option value="">Please select</option>
        {STATES.map((state) => <option key={state} value={state}>{state}</option>)}
      </select>
    </div>
  )
}

StateDropdown.propTypes = {
  stepId: PropTypes.number.isRequired,
}
