import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setOpen,
  setStatus,
  removeFromCart,
  updateQuantityInCart,
  selectCheckoutUrl,
  selectOpen,
  selectContents,
  selectTotals,
  selectQuantity,
  selectUUID,
  selectLoading,
  selectStatus,
} from '@/public/redux/cartSlice'
import { numberToCurrency } from '@/shared/helpers/String'
import LoadingOverlay from '@/public/LoadingOverlay'
import CartItem from '@/public/cart/CartItem'
import CartLedger from '@/public/cart/CartLedger'

export default function CartDrawer({ checkoutUrl, customerIsMember, debug = false }) {
  const drawer = useRef(null)

  const dispatch = useDispatch()
  const open = useSelector(selectOpen)
  const uuid = useSelector(selectUUID)
  const loading = useSelector(selectLoading)
  const contents = useSelector(selectContents)
  const cartStatus = useSelector(selectStatus)
  const quantity = useSelector(selectQuantity)
  const totals = useSelector(selectTotals)
  const fullCheckoutUrl = `${checkoutUrl}/${uuid}`
  const isError = cartStatus === 'error'

  const [shouldShowLoading, setShouldShowLoading] = useState(null)

  const handleClose = () => {
    dispatch(setOpen(false))
  }

  const handleClickOutside = (e) => {
    if (
      drawer.current
      && drawer.current.classList.contains('open')
      && drawer.current.contains(e.target) === false
    ) {
      handleClose()
    }
  }

  const handleQuantityChange = (item, quantity) => {
    dispatch(updateQuantityInCart({ item, quantity }))
  }

  const handleRemoveItem = (event, item) => {
    event.stopPropagation()
    dispatch(removeFromCart(item))
  }

  const preloadCheckout = () => {
    window.__preloader = window.setTimeout(() => {
      if (uuid && uuid !== '') {
        const prefetch = document.createElement('link')
        prefetch.setAttribute('rel', 'prefetch')
        prefetch.setAttribute('href', fullCheckoutUrl)
        document.head.appendChild(prefetch)

        const prerender = document.createElement('link')
        prefetch.setAttribute('rel', 'prerender')
        prefetch.setAttribute('href', fullCheckoutUrl)
        document.head.appendChild(prerender)
      }
    }, 400)
  }

  const cancelPreloadTimer = () => {
    window.clearTimeout(window.__preloader)
  }

  useEffect(() => {
    setShouldShowLoading(loading || /updating/i.test(cartStatus))
  }, [cartStatus, loading])

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  return (
    <div ref={drawer} className={`cart-pane cart-drawer ${open ? 'open' : ''}`} role="dialog" aria-modal="true">
      <LoadingOverlay show={shouldShowLoading} className="tan" />

      <div className="cart-header">
        <div className="h2 underline">Cart</div>
        <button className="btn cart-close p-0" type="button" onClick={handleClose}>
          <i className="icon-times" />
        </button>
      </div>

      <div className="cart-list">
        { contents?.map((item, index) => (
          <CartItem
            key={`cart-item-${index}`}
            data={item.data}
            quantity={item.quantity}
            quantifiable={item.quantifiable}
            subscription={item.subscription}
            pickupOrDelivery={item?.pickup_or_delivery}
            handleQuantityChange={(quantity) => handleQuantityChange(item, quantity)}
            handleRemove={(event) => handleRemoveItem(event, item)}
            customerIsMember={customerIsMember}
          />
        ))}

        {isError ? <div className="text-danger">There was an error loading your cart.</div> : <CartLedger /> }
      </div>

      <div className="cart-footer">
        {/* <div className="cart-footer-actions"> */}
        <div className="row">
          <div className="mb-3 mb-md-0 col-sm-6">
            <button type="button" className="btn btn-secondary btn-block" onClick={handleClose}>
              Continue Shopping
            </button>
          </div>
          {!isError && (
            <div className="mb-3 mb-md-0 col-sm-6">
              <a
                href={fullCheckoutUrl}
                className="btn btn-primary btn-block"
                onMouseEnter={preloadCheckout}
                onMouseLeave={cancelPreloadTimer}
              >
                Checkout &mdash;
                {' '}
                {numberToCurrency(totals.calculated_after_savings)}
              </a>
            </div>
          )}
        </div>

        {/* </div> */}
      </div>
    </div>
  )
}
