import React from 'react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import {
  selectConditionals, selectContents, selectShippingCosts, selectTotals,
} from '@/public/redux/cartSlice'
import { numberToCurrency, pluralize } from '@/shared/helpers/String'
import { customerTimezonePreference } from '@/shared/helpers/Customer'

export default function ModalContentSubscriptionDetails() {
  const conditionals = useSelector(selectConditionals)
  const contents = useSelector(selectContents)
  const shippingCosts = useSelector(selectShippingCosts)
  const totals = useSelector(selectTotals)
  const timezone = rails_timezone.from(customerTimezonePreference)

  console.log('totals:', totals)

  const calculatedNextOrderDateFor = (item = null) => {
    if (!item) { return null }

    let nextOrderDate = DateTime.now().plus({ days: item.data.subscription_frequency_in_days })

    if (timezone) { nextOrderDate = nextOrderDate.setZone(timezone) }

    return nextOrderDate.toLocaleString(DateTime.DATE_SHORT)
  }

  return (
    <div className="modal-body">
      {
      contents?.filter((item) => item?.subscription).map((item, index) => (
        <div key={item.data.sku} className={`${index >= 1 ? 'mt-4 pt-4 border-top' : ''} row`}>

          <div className="col-4 col-md-3 col-lg-2">
            <div className="cart-list-item-image">
              <img src={item.data.featured_image_url} alt={item.data.featured_image_alt_text} />
            </div>
          </div>

          <div className="col-8 col-md-9 col-lg-10">
            <h4 className="cart-list-item-details-name h4">{ item.data.name }</h4>

            <p className="mb-3">
              Your subscription to
              {' '}
              <strong>
                {item.quantity}
                {' '}
                "
                <em>{item.data.name}</em>
                "
                {' '}
                {pluralize(item.quantity, item.data.category.replace(/_/g, ' '))}
              </strong>
              &nbsp;
              will be automatically renewed every
              &nbsp;
              <strong className="d-inline-block">
                {item.data.subscription_frequency_in_days}
                {' '}
                days
              </strong>
              {' '}
              for&nbsp;
              <strong>
                {`${numberToCurrency(totals.calculated_after_savings - shippingCosts.supplement_flat_rate)} plus ${numberToCurrency(shippingCosts.supplement_flat_rate)} shipping`}
              </strong>
              .
            </p>

            <p className="mb-0">
              Your next order will automatically occur on
              {' '}
              <strong>{calculatedNextOrderDateFor(item)}</strong>
              {' '}
              if you order today.
            </p>
          </div>

        </div>
      ))
    }

      { conditionals.contains_subscriptions && parseInt(totals.calculated_shipping_cost) === 0
      && (
      <div className="mt-5 pt-4 w-100 text-center border-top">
        <h6>Congratulations! You've qualified for free shipping on this order.</h6>
        <p className="mb-0">However, please note that future subscription renewals will continue to be subject to shipping fees.</p>
      </div>
      )}
    </div>
  )
}
