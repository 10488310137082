import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'

$(() => {
  if (document.getElementById('lab-pricing-modal')) {
    const modal = new bootstrap.Modal('#lab-pricing-modal', {
      keyboard: false,
      backdrop: 'static',
    })

    modal.show()
  }
});
