import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { validationPatterns } from '@/shared/helpers/Errors'

export default function AddressFields({
  customer = null, states = [], provinces = [], type = 'address', hasMultiple = false, countries = [],
}) {
  const {
    register, setValue, watch, formState: { errors },
  } = useFormContext()

  const CCNumberFormat = /^[\d]{4}[\s|\-|_]?[\d]{4}[\s|\-|_]?[\d]{4}[\s|\-|_]?[\d]{4}$/

  const watchCountry = watch(`customer.${type}.country`, 'US')

  useEffect(() => {
    if (customer) {
      setValue(`customer.${type}.address_line_1`, customer?.addresses?.[type]?.line_1)
      setValue(`customer.${type}.address_line_2`, customer?.addresses?.[type]?.line_2)
      setValue(`customer.${type}.city`, customer?.addresses?.[type]?.city)
      setValue(`customer.${type}.state`, customer?.addresses?.[type]?.state)
      setValue(`customer.${type}.zip`, customer?.addresses?.[type]?.zip)
      setValue(`customer.${type}.country`, customer?.addresses?.[type]?.country || 'US')
    }
  }, [customer])

  return (
    <>
      { hasMultiple
        && (
        <h4 className="text-capitalize mt-5 mb-2">
          {type}
          {' '}
          Address
        </h4>
        )}

      <div className="row mb-3">
        <div className="col">
          <label htmlFor={`${type}.country`} className={`form-label ${errors?.customer?.[type]?.country ? 'error' : ''}`}>Country</label>
          <select
            id={`${type}.country`}
            className={`form-control form-select react ${errors?.customer?.[type]?.country ? 'error' : ''}`}
            type="text"
            autoComplete="country"
            {...register(`customer.${type}.country`, {
              required: 'This field is required',
            })}
          >
            {countries.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <label htmlFor={`${type}.address-line-1`} className={`form-label ${errors?.customer?.[type]?.address_line_1 ? 'error' : ''}`}>Address Line 1</label>
          <input
            id={`${type}.address-line-1`}
            className={`form-control ${errors?.customer?.[type]?.address_line_1 ? 'error' : ''}`}
            type="text"
            autoComplete="address-line1"
            {...register(`customer.${type}.address_line_1`, {
              required: 'This field is required',
              validate: (v) => CCNumberFormat.test(v) === false || 'Invalid address',
            })}
          />
          {errors.customer?.billing?.address_line_1 && <p className="text-danger">{errors.customer.billing.address_line_1.message}</p>}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <label htmlFor={`${type}.address-line-2`} className={`form-label ${errors?.customer?.[type]?.address_line_2 ? 'error' : ''}`}>Address Line 2</label>
          <input
            id={`${type}.address-line-2`}
            className={`form-control ${errors?.customer?.[type]?.address_line_2 ? 'error' : ''}`}
            type="text"
            autoComplete="address-line2"
            {...register(`customer.${type}.address_line_2`, {
              validate: (v) => CCNumberFormat.test(v) === false || 'Invalid address',
            })}
          />
          {errors.customer?.billing?.address_line_2 && <p className="text-danger">{errors.customer.billing.address_line_2.message}</p>}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <label htmlFor={`${type}.city`} className={`form-label ${errors?.customer?.[type]?.city ? 'error' : ''}`}>City</label>
          <input
            id={`${type}.city`}
            className={`form-control ${errors?.customer?.[type]?.city ? 'error' : ''}`}
            type="text"
            autoComplete="address-level2"
            {...register(`customer.${type}.city`, {
              required: 'This field is required',
              validate: (v) => CCNumberFormat.test(v) === false || 'Invalid address',
            })}
          />
          {errors.customer?.billing?.city && <p className="text-danger">{errors.customer.billing.city.message}</p>}
        </div>
      </div>

      <div className="row mb-3">
        {watchCountry === 'US'
          && (
          <div className="col-6">
            <label htmlFor={`${type}.state`} className={`form-label ${errors?.customer?.[type]?.state ? 'error' : ''}`}>State</label>
            <select
              id={`${type}.state`}
              className={`form-control form-select react ${errors?.customer?.[type]?.state ? 'error' : ''}`}
              autoComplete="address-level1"
              {...register(`customer.${type}.state`, {
                required: 'This field is required',
              })}
            >
              <option value="">Please select</option>
              { states.map(({ code, name }) => <option key={code} value={code}>{name}</option>) }
            </select>
          </div>
          )}
        {watchCountry === 'CA'
          && (
          <div className="col-6">
            <label htmlFor={`${type}.state`} className={`form-label ${errors?.customer?.[type]?.state ? 'error' : ''}`}>Province</label>
            <select
              id={`${type}.state`}
              className={`form-control form-select react ${errors?.customer?.[type]?.state ? 'error' : ''}`}
              autoComplete="address-level1"
              {...register(`customer.${type}.state`, {
                required: 'This field is required',
              })}
            >
              <option value="">Please select</option>
              {provinces.map((province) => (
                <option key={province.code} value={province.code}>
                  {province.name}
                </option>
              ))}
            </select>
          </div>
          )}
        <div className="col-6">
          <label htmlFor={`${type}.zip`} className={`form-label ${errors?.customer?.[type]?.zip ? 'error' : ''}`}>Zip Code</label>
          <input
            id={`${type}.zip`}
            className={`form-control ${errors?.customer?.[type]?.zip ? 'error' : ''}`}
            type="text"
            autoComplete="postal-code"
            maxLength="5"
            {...register(`customer.${type}.zip`, {
              required: 'This field ig required', pattern: validationPatterns.zip,
            })}
          />
        </div>
        <div />
      </div>
    </>
  )
}
