const carouselOne = () => {
  const track = document.querySelector('.carousel_track')
  const nextButton = document.querySelector('.chevron-right')
  const prevButton = document.querySelector('.chevron-left')
  const barsNav = document.querySelector('.indicator-container')
  const sliderNumber = document.querySelector('.slider-container .button-container .slider-number')
  if (!track || !nextButton || !prevButton || !barsNav || !sliderNumber) {
    return
  }

  const bars = Array.from(barsNav.children)
  const slides = Array.from(track.children)

  const slideWidth = slides[0].getBoundingClientRect().width

  const setSlidePosition = (slide, index) => {
    // eslint-disable-next-line no-param-reassign
    slide.style.left = `${slideWidth * index}px`
  }
  slides.forEach(setSlidePosition)

  const moveToSlide = (currentSlide, targetSlide) => {
    // eslint-disable-next-line no-param-reassign
    if (!targetSlide || !currentSlide) { return }
    track.style.transform = `translateX(-${targetSlide.style.left})`
    currentSlide.classList.remove('current_slide')
    targetSlide.classList.add('current_slide')
  }

  const updateBars = (currentBar, targetBar) => {
    if (!currentBar || !targetBar) { return }
    currentBar.classList.remove('current_slide')
    targetBar.classList.add('current_slide')
  }

  const updateSliderNumber = (index) => {
    if (index === -1) {
      return
    }
    sliderNumber.innerHTML = index + 1
  }

  const hideShowArrows = (targetIndex) => {
    if (targetIndex === 0) {
      prevButton.classList.add('is-done')
      nextButton.classList.remove('is-done')
    } else if (targetIndex === slides.length - 1) {
      prevButton.classList.remove('is-done')
      nextButton.classList.add('is-done')
    } else {
      prevButton.classList.remove('is-done')
      nextButton.classList.remove('is-done')
    }
  }

  // leftbutton
  prevButton.addEventListener('click', () => {
    const currentSlide = track.querySelector('.current_slide')
    const prevSlide = currentSlide.previousElementSibling
    const currentBar = barsNav.querySelector('.current_slide')
    const prevBar = currentBar.previousElementSibling
    const prevIndex = slides.findIndex((slide) => slide === prevSlide)

    moveToSlide(currentSlide, prevSlide)
    updateBars(currentBar, prevBar)
    updateSliderNumber(prevIndex)
    if (prevIndex !== -1) {
      hideShowArrows(prevIndex)
    }
  })

  // rightbutton
  nextButton.addEventListener('click', () => {
    const currentSlide = track.querySelector('.current_slide')
    const nextSlide = currentSlide.nextElementSibling
    const currentBar = barsNav.querySelector('.current_slide')
    const nextBar = currentBar.nextElementSibling
    const nextIndex = slides.findIndex((slide) => slide === nextSlide)

    moveToSlide(currentSlide, nextSlide)
    updateBars(currentBar, nextBar)
    updateSliderNumber(nextIndex)
    if (nextIndex !== -1) {
      hideShowArrows(nextIndex)
    }
  })

  barsNav.addEventListener('click', (e) => {
    const targetBar = e.target.closest('button')

    if (!targetBar) { return }

    const currentSlide = track.querySelector('.current_slide')
    const currentBar = barsNav.querySelector('.current_slide')
    const targetIndex = bars.findIndex((bar) => bar === targetBar)
    const targetSlide = slides[targetIndex]

    moveToSlide(currentSlide, targetSlide)
    updateBars(currentBar, targetBar)
    hideShowArrows(targetIndex)
  })
}

$(() => carouselOne())

