export function hourTo12HourFormat(rawHour) {
  // it is pm if hours from 12 onwards
  const suffix = rawHour >= 12 ? 'pm' : 'am'

  // only -12 from hour if it is greater than 12 (if not back at midnight)
  let hour = rawHour > 12 ? rawHour - 12 : rawHour

  // if 00 then it is 12 am
  hour = hour == '00' ? 12 : hour

  return hour + suffix
}

export function nameOfDayFromNumber(number) {
  switch (true) {
    case number === 0: return 'Sunday'
    case number === 1: return 'Monday'
    case number === 2: return 'Tuesday'
    case number === 3: return 'Wednesday'
    case number === 4: return 'Thursday'
    case number === 5: return 'Friday'
    case number === 6: return 'Saturday'
    default: return ''
  }
}
