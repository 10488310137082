import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { accessToken } from '@/shared/helpers/CSRF'
import { customerSignedIn, customerEmail } from '@/shared/helpers/Customer'
import axios from 'axios'

function NotifySuccess() {
  return (
    <p className="subtitle text-success mb-0">You&apos;ll be notified when this product is back in stock!</p>
  )
}
function NotifyFailure() {
  return (
    <p className="subtitle text-danger mb-0">Oops! Something went wrong. Please try again later.</p>
  )
}

function Form({ notify, wide = false }) {
  const [email, setEmail] = useState()
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const [formValid, setFormValid] = useState(true)

  const checkValidity = () => {
    if (!inputRef.current.checkValidity()) {
      setFormValid(false)
      return false
    }
    return true
  }

  const handleBlur = () => {
    if (email) {
      if (checkValidity()) {
        setFormValid(true)
      }
    } else {
      setFormValid(true)
      formRef.current.reset()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!checkValidity()) {
      inputRef.current.focus()
      return
    }

    notify(email)
  }

  return (
    <form className="w-100" onSubmit={handleSubmit} noValidate ref={formRef}>
      <div className={wide ? '' : 'gap-2 d-flex flex-col'}>
        <input
          className={`form-control${formValid ? '' : ' is-invalid'}${wide ? ' mb-1' : ''}`}
          defaultValue=""
          type="email"
          required
          placeholder={wide ? 'Enter your email to be notified' : 'Email'}
          onChange={(e) => setEmail(e.target.value.trim())}
          ref={inputRef}
          onBlur={handleBlur}
        />
        <div className={`invalid-feedback mb-3 ${wide ? 'd-block' : 'd-none'}`}>{formValid ? '' : 'Please enter your email address.'}</div>
        <button
          type="submit"
          className={`btn btn-primary m-0${wide ? ' btn-block' : ''}`}
        >
          {wide ? 'Notify me when available' : <i className="icon-chevron-right m-0" />}
        </button>
      </div>
    </form>
  )
}

Form.propTypes = {
  wide: PropTypes.bool.isRequired,
  notify: PropTypes.func.isRequired,
}

export default function NotifyWhenAvailable({
  product, wide = false, awaitedProductIds,
}) {
  const localIds = JSON.parse(localStorage.getItem('awaited_product_ids')) || []
  const awaitedIds = customerSignedIn ? awaitedProductIds : localIds
  const requested = awaitedIds.includes(product.id)
  const [notifyState, setNotifyState] = useState(requested ? 'success' : 'none')

  const notify = async (email) => {
    try {
      await axios({
        method: 'POST',
        url: '/api/product_notification_requests',
        headers: { 'X-CSRF-Token': accessToken },
        data: { email, product_id: product.id },
      })
      setNotifyState('success')
      if (!customerSignedIn) {
        localStorage.setItem('awaited_product_ids', JSON.stringify([...awaitedProductIds, product.id]))
      }
    } catch (_) {
      setNotifyState('error')
    }
  }

  function handleNotify() {
    if (customerEmail) {
      notify(customerEmail)
    } else {
      setNotifyState('editing')
    }
  }

  if (notifyState === 'success') {
    return <NotifySuccess />
  }
  if (notifyState === 'error') {
    return <NotifyFailure />
  }
  if (notifyState === 'editing') {
    return <Form wide={wide} notify={notify} />
  }
  if (notifyState === 'none') {
    return (
      <button
        type="button"
        className="btn btn-primary btn-block m-0"
        onClick={() => handleNotify()}
      >
        {wide ? 'Notify me when available' : 'Notify me' }
      </button>
    )
  }
}

NotifyWhenAvailable.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  awaitedProductIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  wide: PropTypes.bool,
}

NotifyWhenAvailable.defaultProps = {
  wide: false,
}
