import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    quantity: 1,
  },
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = Number(action.payload)
    },
  },
})

export const {
  setQuantity,
} = productSlice.actions

export const selectQuantity = (state) => state.product.quantity

export default productSlice.reducer
