import React from 'react'
import PropTypes from 'prop-types';

export default function Header({
  title,
  logoUrl,
  companyName,
  back,
}) {
  return (
    <header id="as-app-header" className="sticky-header mb-3">
      <div className="container">
        <div className="exit">
          {back && (
            <button className="btn btn-tertiary ps-1 pe-3" type="button" onClick={back}>
              <i className="icon-chevron-left" />
              {' '}
              Back
            </button>
          )}
        </div>

        <h1 className="heading h5 mb-0">{ title }</h1>

        <div className="brand">
          <a href="/">
            <img src={logoUrl} alt={companyName} />
          </a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
}
