import React, { useState } from 'react'
import InputMask from 'react-input-mask'

export default function PhoneInput({ resource_name, current_phone, invalid_class_name }) {
  const [phone, setPhone] = useState(current_phone)

  return (
    <InputMask
      name={`${resource_name}[phone]`}
      className={`form-control ${invalid_class_name}`}
      type="tel"
      mask={phone ? '999-999-9999' : null}
      maskchar={null}
      value={phone}
      alwaysShowMask
      autoComplete="tel-national"
      autoCorrect="off"
      onChange={(e) => e.preventDefault()}
      onInput={(e) => setPhone(e.target.value)}
    />
  )
}
