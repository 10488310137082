import React from 'react'
import { useSelector } from 'react-redux'
import { selectErrors } from '@/public/redux/wizardSlice'

export default function Errors({ className = 'text-center', passedErrors = [] }) {
  const errors = useSelector(selectErrors)
  const errorsToDisplay = passedErrors.length !== 0 ? passedErrors : errors

  return (
    <>
      { errorsToDisplay
      && (
      <p className={`form-text error mb-n4 ${className}`}>
        {
          errorsToDisplay.map((error, index) => (
            <span key={`error-${index}`} className={index > 0 ? 'd-block' : 'd-inline'}>
              {error}
            </span>
          ))
        }
      </p>
      )}
    </>
  )
}
