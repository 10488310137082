import store from '@/public/redux/store'
import { toCamelcase } from '@/shared/helpers/String'
import { useDispatch } from 'react-redux'
import { loadStep } from '@/public/redux/wizardSlice'

const utils = {
  parsedAction: (string = '') => {
    const [action, condition] = string.split('|')

    return {
      action: action.split(':'),
      condition: condition ? condition.split(':') : [],
    }
  },

  executeActions: (actions = [], executableActions = {}, debug = false) => {
    if (!actions) { return }

    actions.forEach((action) => {
      const parsedAction = utils.parsedAction(action)
      let [actionFn, actionParams, trackingId] = parsedAction.action
      let [conditionFn, conditionParams] = parsedAction.condition
      let conditionResult = null

      // Convert the function string from snake_case to camelCase
      actionFn = toCamelcase(actionFn)
      if (conditionFn) { conditionFn = toCamelcase(conditionFn) }

      // ---------------------------------------------------------------
      // IF A CONDITION EXISTS, ATTEMPT TO DETERMINE ITS BOOLEAN RESULT
      // ---------------------------------------------------------------
      if (conditionFn) {
        console.log(
          conditionFn,
          conditionParams,
        )

        switch (true) {
          // LOCAL: possibly defined in eithera wizard step
          // or in globalWizardSpecificActions for whichever
          // particular wizard component is in-use
          case typeof executableActions[conditionFn] === 'function':
            conditionResult = executableActions[conditionFn](conditionParams)
            break

          // GLOBAL: defined here in the utils object
          case typeof utils.executableActions[conditionFn] === 'function':
            conditionResult = utils.executableActions[conditionFn](conditionParams)
            break

          default:
            if (debug && console) { console.error(`"${conditionFn}" is not a function`) }
        }
      }

      // ---------------------------------------------------------------
      // ATTEMPT TO EXECUTE THE ACTION IF CONDITIONALLY ALLOWED TO
      // ---------------------------------------------------------------
      if (
        (!conditionFn && conditionResult === null)
          || (Boolean(conditionFn) && conditionResult === true)
      ) {
        switch (true) {
          // LOCAL: possibly defined in eithera wizard step
          // or in globalWizardSpecificActions for whichever
          // particular wizard component is in-use
          case typeof executableActions[actionFn] === 'function':
            executableActions[actionFn](actionParams, trackingId)
            break

          // GLOBAL: defined here in the utils object
          case typeof utils.executableActions[actionFn] === 'function':
            utils.executableActions[actionFn](actionParams, trackingId)
            break

          default:
            if (debug && console) { console.error(`"${actionFn}" is not a function`) }
        }
      }
    })
  },

  // super basic globally defined actions that will
  // function on ANY possible wizard component
  // examples: goto, return, exit, etc.
  executableActions: {
    goto: (stepId) => {
      store.dispatch(loadStep(stepId))
    },
  },
}

export default utils
