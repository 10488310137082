import React from 'react'
import { useSelector } from 'react-redux'
import { selectVerified } from '@/public/redux/recaptchaSlice'

export default function Button({
  type = 'submit',
  text = 'Continue',
  className = 'btn-primary',
  align = 'text-center mt-5',
  standalone = true,
  disabled,
  children,
  onClick = null,
  customerExists = false,
  loginUrl = '',
}) {
  const verified = useSelector(selectVerified)

  const handleClick = (e) => {
    if (onClick !== null) {
      e.preventDefault()
      onClick()
    }
    return true
  }

  const renderedButton = () => (
    customerExists
      ? (<a href={loginUrl} className={`btn px-4 ${className}`}>Login</a>)
      : (
        <button
          className={`btn px-4 ${className}`}
          type={type}
          disabled={!verified || disabled}
          onClick={(e) => handleClick(e)}
        >
          { children || text }
        </button>
      )
  )

  return (
    <>
      {
      !standalone ? renderedButton() : (
        <div className={align}>
          { renderedButton() }
        </div>
      )
    }
    </>
  )
}
