import React, { useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Render a dropdown for selecting multiple options.
 *
 * A dropdown followed by a list of selected items and buttons to remove the items.
 *
 * The data is serialized specifically for a PostgreSQL array type.
 *
 * @typedef {{label: string, value: string}} Option
 * @param {string} placeholder the placeholder of the dropdown
 * @param {string} resourceName the model name, underscored, such as "doctor_profile"
 * @param {string} fieldName the field name in the model, such as "licensed_states"
 * @param {Option[]} allOptionsEncoded an array of Options
 * @param {string[]} currentValuesEncoded the current values, should correspond with
 *   the values in allOptionsEncoded
 */
export default function SelectList({
  placeholder = '',
  resourceName = '',
  fieldName = '',
  allOptionsEncoded = 'W10=', // [] encoded in Base64
  currentValuesEncoded = 'W10=',
}) {
  const startingValues = JSON.parse(atob(currentValuesEncoded))
  const allOptions = JSON.parse(atob(allOptionsEncoded))
  const allValues = allOptions.map((option) => option.value)
  const optionMap = {}
  allOptions.forEach((option) => { optionMap[option.value] = option.label })

  const [selectedValues, setSelectedValues] = useState(startingValues)
  const [newValue, setNewValue] = useState(undefined)

  const handleAdd = () => {
    setSelectedValues([...selectedValues, newValue])
    setNewValue(undefined)
  }

  const handleRemove = (e) => {
    setSelectedValues([...selectedValues].filter((value) => value !== e.target.value))
  }

  const remainingValues = () => allValues.filter((value) => !selectedValues.includes(value))

  return (
    <div className="select-list">
      <input
        type="hidden"
        hidden
        aria-hidden
        name={`${resourceName}[${fieldName}]`}
        value={`{${selectedValues}}`}
      />
      <select
        id={fieldName}
        name={fieldName}
        className="form-control form-select mb-2"
        defaultValue=""
        onChange={(e) => {
          setNewValue(e.target.value)
          e.target.value = ''
        }}
      >
        <option disabled hidden default value="">{placeholder}</option>
        {remainingValues().map((value) => (
          <option value={value} key={value}>{optionMap[value]}</option>
        ))}
      </select>
      {newValue
        && (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={handleAdd}
          >
            Add
            {' '}
            {optionMap[newValue]}
          </button>
        )}
      <ul className="ps-0">
        {selectedValues.sort().map((value) => (
          <li key={`li-${value}`} className="d-flex justify-content-between align-items-center my-1">
            <span>{optionMap[value]}</span>
            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={handleRemove}
              value={value}
            >
              &mdash;
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

SelectList.propTypes = {
  placeholder: PropTypes.string,
  resourceName: PropTypes.string,
  fieldName: PropTypes.string,
  allOptionsEncoded: PropTypes.string,
  currentValuesEncoded: PropTypes.string,
}

SelectList.defaultProps = {
  placeholder: '',
  resourceName: '',
  fieldName: '',
  allOptionsEncoded: 'W10=',
  currentValuesEncoded: 'W10=',
}
