import React from 'react';
import PropTypes from 'prop-types';

export default function Textbox({ stepId, type, previousAnswer = '' }) {
  return (
    <div className="mb-4">
      <input
        id={stepId}
        className="form-control"
        autoCorrect="off"
        required
        name={stepId}
        type={type === 'email' ? 'email' : 'text'}
        defaultValue={previousAnswer}
      />
    </div>
  )
}

Textbox.propTypes = {
  stepId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  previousAnswer: PropTypes.string,
}

Textbox.defaultProps = {
  previousAnswer: '',
}
