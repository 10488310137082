import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import { indexOfItemInCart } from '@/shared/helpers/Object'
import { gaAddToCart, gaRemoveFromCart } from '../../shared/helpers/googleAnalytics'

const initialState = {
  debug: false,
  status: 'fresh',
  loaded: false,
  loading: false,
  error: null,
  open: false,
  uuid: null,
  user_id: null,
  customer: null,
  order: null,
  stripeCustomer: null,
  discount_code: null,
  contents: null,
  contains_a_meal_plan: null,
  contains_subscriptions: null,
  calculated_amount_saved: null,
  calculated_shipping_cost: null,
  calculated_service_fee: null,
  calculated_addons_cost: null,
  calculated_taxes: null,
  calculated_total_before_savings: null,
  calculated_total_after_savings: null,
  payment_success: null,
  order_confirmation_id: null,
  shipping_costs: null,
  paymentRequestOpen: false,
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadCart: (state, action) => {
      state.uuid = action.payload.uuid
      state.user_id = action.payload.user_id
      state.contents = action.payload.contents
      state.discount_code = action.payload.discount_code
      state.calculated_amount_saved = action.payload.calculated_amount_saved
      state.calculated_shipping_cost = action.payload.calculated_shipping_cost
      state.calculated_service_fee = action.payload.calculated_service_fee
      state.calculated_addons_cost = action.payload.calculated_addons_cost
      state.calculated_total_before_savings = action.payload.calculated_total_before_savings
      state.calculated_total_after_savings = action.payload.calculated_total_after_savings
      state.contains_a_meal_plan = action.payload.contains_a_meal_plan
      state.contains_subscriptions = action.payload.contains_subscriptions
      console.log(action.payload)

      // save/update UUID in visitor's browser's localStorage
      if (action.payload.uuid !== null) {
        // if the customer is signed in and a UUID already exists, because they
        // added items to a new cart before logging in, save it so we can merge it later
        if (
          customerSignedIn === 'true'
          && !/merging/.test(state.status)
          && window.localStorage.getItem(cartSlice.name)
          && !window.localStorage.getItem(`${cartSlice.name}-temp`)
          && (state.uuid && state.uuid !== window.localStorage.getItem(cartSlice.name))
        ) {
          window.localStorage.setItem(`${cartSlice.name}-temp`, window.localStorage.getItem(cartSlice.name))
          window.localStorage.setItem(cartSlice.name, action.payload.uuid)
          state.status = 'needs-merged'
        } else {
          window.localStorage.setItem(cartSlice.name, action.payload.uuid)
        }
      }

      state.loaded = true
    },

    clearCart: (state, _) => {
      window.localStorage.removeItem(cartSlice.name)
      state.uuid = null
      state.discount_code = null
      state.status = 'creating'
    },

    setStatus: (state, action) => {
      state.status = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setError: (state, action) => {
      state.error = action.payload
    },

    setOpen: (state, action) => {
      state.open = Boolean(action.payload)
      document.body.classList[action.payload ? 'add' : 'remove']('noscroll')
    },

    setUserId: (state, action) => {
      state.user_id = action.payload
    },

    setPaymentSuccess: (state, action) => {
      state.payment_success = action.payload
    },

    setOrderConfirmationId: (state, action) => {
      state.order_confirmation_id = action.payload
    },

    setCustomer: (state, action) => {
      state.customer = action.payload
    },

    setOrder: (state, action) => {
      state.order = action.payload
    },

    setStripeCustomer: (state, action) => {
      state.stripeCustomer = action.payload
    },

    setDiscountCode: (state, action) => {
      state.discount_code = action.payload
    },

    resetDiscountMessage: (state, action) => {
      state.discount_code = { ...state.discount_code, ...{ message: '' } }
    },

    setShippingCosts: (state, action) => {
      state.shipping_costs = action.payload
    },

    setPaymentRequestOpen: (state, action) => {
      console.log('payment request action:', action)
      state.paymentRequestOpen = action.payload
    },

    /*
     * Every other add/update/remove reducer intentionally calls
     * this reducer so that every change to items in the cart sorts
     * the cart contents, updates the visitor's browser's localStorage,
     * and saves the cart to the API endpoint.
     */
    setContents: (state, action) => {
      // sort and update contents
      state.contents = action.payload.sort((a, b) => {
        const name1 = a?.subscription ? `${a.data.name} - Subscription` : a.data.name
        const name2 = b?.subscription ? `${b.data.name} - Subscription` : b.data.name
        return name1 > name2 ? 1 : -1
      })

      state.status = 'updated'
    },

    addToCart: (state, action) => {
      const newItem = action.payload
      const { quantity } = action.payload
      const _isItemInCart = isItemInCart(state, newItem)
      const updatedContents = state.contents ? [...state.contents] : []

      // if the item is already in the cart...
      if (_isItemInCart.answer) {
        // if trying to add a subscription for a second time open
        // the cart to hint that the visitor is doing something dumb
        if ('subscription' in newItem) {
          cartSlice.caseReducers.setOpen(state, { payload: true })

          // otherwise, increment the quantity of the item by the
          // passed in quantity (default is 1 but could be many)
        } else {
          const itemToUpdate = _isItemInCart.item
          cartSlice.caseReducers.updateQuantityInCart(state, {
            payload: {
              item: { data: itemToUpdate.data },
              quantity: itemToUpdate.quantity + quantity,
            },
          })
        }

        // otherwise, add the item to the cart
      } else {
        updatedContents.push(newItem)
        cartSlice.caseReducers.setContents(state, { payload: updatedContents })
      }

      gaAddToCart(newItem.data, quantity)

      state.status = state.uuid === null ? 'creating' : 'updating'
    },

    updateQuantityInCart: (state, action) => {
      const { item } = action.payload
      const { quantity } = action.payload
      const updatedContents = [...state.contents]
      const index = indexOfItemInCart(updatedContents, item)
      const updatedItem = updatedContents[index]

      const AddingQuantity = quantity - item.quantity

      if (AddingQuantity > 0) {
        gaAddToCart(item.data, AddingQuantity)
      } else {
        gaRemoveFromCart(item.data, AddingQuantity * -1)
      }

      // update the item's quantity
      updatedItem.quantity = quantity
      updatedContents[index] = updatedItem

      // finally, update the cart state
      cartSlice.caseReducers.setContents(state, { payload: updatedContents })

      state.status = 'updating'
    },

    removeFromCart: (state, action) => {
      const item = action.payload
      const removingContent = [...state.contents].find((content) => content.id === item.id)
      const updatedContents = [...state.contents].filter((_item) => (item.data.sku === _item.data.sku && item?.subscription === _item?.subscription) === false)

      gaRemoveFromCart(item.data, removingContent.quantity)

      cartSlice.caseReducers.setContents(state, { payload: updatedContents })

      // auto-close the cart once the last item has been removed
      if (updatedContents.length === 0) {
        cartSlice.caseReducers.setOpen(state, { payload: false })
        state.discount_code = null
      }

      state.status = 'updating'
    },
  },
})

export const {
  loadCart,
  clearCart,
  resetDiscountMessage,
  setCustomer,
  setOrder,
  setStripeCustomer,
  setDiscountCode,
  setCheckoutUrl,
  setOrderConfirmationId,
  setLoading,
  setError,
  setOpen,
  setShippingCosts,
  setPaymentRequestOpen,
  setStatus,
  setPaymentSuccess,
  setUserId,
  addToCart,
  updateQuantityInCart,
  removeFromCart,
} = cartSlice.actions

export const selectLoaded = (state) => state.cart.loaded
export const selectLoading = (state) => state.cart.loading
export const selectError = (state) => state.cart.error
export const selectStatus = (state) => state.cart.status
export const selectOpen = (state) => state.cart.open
export const selectUUID = (state) => state.cart.uuid
export const selectUserId = (state) => state.cart.user_id
export const selectDiscountCode = (state) => state.cart.discount_code
export const selectContents = (state) => state.cart.contents
export const selectCustomer = (state) => state.cart.customer
export const selectOrder = (state) => state.cart.order
export const selectShippingCosts = (state) => state.cart.shipping_costs
export const selectStripeCustomer = (state) => state.cart.stripeCustomer
export const selectPaymentSuccess = (state) => state.cart.payment_success
export const selectOrderConfirmationId = (state) => state.cart.order_confirmation_id
export const selectContainsAMealPlan = (state) => state.cart.contains_a_meal_plan
export const selectPaymentRequestOpen = (state) => state.cart.paymentRequestOpen

export const customerSignedIn = window.localStorage.getItem('customer-signed-in')

export const selectQuantity = (state) => {
  let quantity = 0
  state.cart.contents?.forEach((item) => {
    quantity += Number(item.quantity)
  })
  return quantity
}

export const selectConditionals = (state) => ({
  contains_a_meal_plan: state.cart.contains_a_meal_plan,
  contains_subscriptions: state.cart.contains_subscriptions,
})

export const selectTotals = (state) => ({
  calculated_before_savings: Number(state.cart.calculated_total_before_savings),
  calculated_after_savings: Number(state.cart.calculated_total_after_savings),
  calculated_amount_saved: Number(state.cart.calculated_amount_saved),
  calculated_shipping_cost: Number(state.cart.calculated_shipping_cost),
  calculated_service_fee: Number(state.cart.calculated_service_fee),
  calculated_addons_cost: Number(state.cart.calculated_addons_cost),
  calculated_taxes: Number(state.cart.calculated_taxes),
})

export const isItemInCart = (state, item) => {
  try {
    let contents = 'cart' in state ? state.cart.contents : state.contents
    contents = contents ? [...contents] : []

    const matchingItem = contents?.filter((_item) => _item.data.sku === item.data.sku && _item?.subscription === item?.subscription)[0]

    return {
      answer: Boolean(matchingItem),
      item: matchingItem,
    }
  } catch (e) {
    if (console) { console.error(e) }
    return { answer: false, item: null }
  }
}

export const fetchCustomer = (user_id) => async (dispatch, getState) => {
  axios({
    method: 'get',
    url: `/api/users/${user_id}`,
  })
    .then(({ data: customer }) => {
      dispatch(setCustomer(customer))
    })
}

export const updateOrCreateCart = () => async (dispatch, getState) => {
  const { uuid } = getState().cart
  const { contents } = getState().cart
  const { user_id } = getState().cart
  const isNewCart = getState().cart.status === 'creating'

  axios({
    method: isNewCart ? 'post' : 'patch',
    url: `/api/cart/${isNewCart ? '' : uuid}`,
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      attributes: { user_id },
      cart: { contents: contents || [] },
    },
  })
    .then(({ data }) => {
      if (getState().cart.debug && console) { console.log(data) }

      if (data) {
        dispatch(loadCart({
          uuid: data.uuid,
          contents: data.contents,
          contains_a_meal_plan: data.contains_a_meal_plan,
          contains_subscriptions: data.contains_subscriptions,
          user_id: data.user_id,
          discount_code: data.discount_code,
          calculated_amount_saved: data.calculated_amount_saved,
          calculated_shipping_cost: data.calculated_shipping_cost,
          calculated_service_fee: data.calculated_service_fee,
          calculated_addons_cost: data.calculated_addons_cost,
          calculated_total_before_savings: data.calculated_total_before_savings,
          calculated_total_after_savings: data.calculated_total_after_savings,
        }))
      }
    })
    .then(() => {
      if (isNewCart) {
        dispatch(setStatus('created'))
      } else {
        dispatch(setStatus('updated'))
      }
    })
    .catch((error) => {
      if (getState().cart.debug && console) { console.log(error.response) }

      // if a 404 is returned, then the cart no longer exists
      // so clear the cart UUID from the visitor's localStorage
      // and reset the visitor's cart by creating a new one
      // with the existing contents WITHOUT any applied discounts
      if (error.response.status === 404) {
        dispatch(clearCart())
        dispatch(updateOrCreateCart())
      }

      if (error.response.status === 500) {
        dispatch(setStatus('error'))
      }

      // for all other errors, we assume that we should hold on to the
      // cart UUID in localStorage so that we can continue trying later
    })
}

export const deleteCart = () => async (dispatch, getState) => {
  const { uuid } = getState().cart

  if (!uuid || uuid === '') { return }

  axios({
    method: 'delete',
    url: `/api/cart/${uuid}`,
    headers: { 'X-CSRF-Token': accessToken },
  })
    .then(({ data }) => {
      if (getState().cart.debug && console) { console.log(data) }
    })
    .catch((error) => {
      if (getState().cart.debug && console) { console.log(error.response) }
    })
  // even if deleting the cart fails on the backend, delete it from the user's
  // localStorage and allow anything depending on the success of deleting a
  // cart to continue (such as redirecting to the order confirmation page)
    .finally(() => {
      window.localStorage.removeItem(cartSlice.name)
      window.localStorage.removeItem(`${cartSlice.name}-temp`)
      dispatch(setStatus('deleted'))
    })
}

// fetch the cart using the UUID known/stored in
// the visitor's browser's localStorage

export const fetchCart = (options = {}) => async (dispatch, getState) => {
  const localUUID = window.localStorage.getItem(cartSlice.name)
  const tempUUID = window.localStorage.getItem(`${cartSlice.name}-temp`)

  if (localUUID === null) { return }

  let url = `/api/cart/${localUUID}`

  if (tempUUID !== null) {
    url += `?merge=true&uuid=${tempUUID}`
    dispatch(setStatus('merging'))
  }

  axios.get(url)
    .then(({ data }) => {
      if (getState().cart.debug && console) { console.log(data) }

      window.localStorage.setItem(cartSlice.name, data.cart.uuid)

      if (data?.merged && data.merged === true) {
        window.localStorage.removeItem(`${cartSlice.name}-temp`)
      }

      return data.cart
    })
    .then((cart) => {
      if (cart.uuid) {
        dispatch(loadCart({
          uuid: cart.uuid,
          contents: cart.contents,
          contains_a_meal_plan: cart.contains_a_meal_plan,
          contains_subscriptions: cart.contains_subscriptions,
          user_id: cart.user_id,
          discount_code: cart.discount_code,
          calculated_amount_saved: cart.calculated_amount_saved,
          calculated_shipping_cost: cart.calculated_shipping_cost,
          calculated_service_fee: cart.calculated_service_fee,
          calculated_addons_cost: cart.calculated_addons_cost,
          calculated_total_before_savings: cart.calculated_total_before_savings,
          calculated_total_after_savings: cart.calculated_total_after_savings,
        }))
      }
    })
    .then(() => {
      dispatch(setStatus('fetched'))
    })
    .catch((error) => {
      if (getState().cart.debug && console) { console.log(error.response) }

      // if a 404 is returned, then the cart no longer exists
      // so clear the cart UUID from the visitor's localStorage
      // and reset the visitor's cart by creating a new one
      // with the existing contents WITHOUT any applied discounts
      if (error.response.status === 404) {
        dispatch(clearCart())
        dispatch(updateOrCreateCart())
      }

      // for all other errors, we assume that we should hold on to the
      // cart UUID in localStorage so that we can continue trying later
    })
}

export const fetchDiscountCode = (code = null) => async (dispatch, getState) => {
  const uuid = getState().cart.uuid || localUUID

  if (code === null || uuid === null) { return }

  dispatch(setLoading(true))

  axios({
    method: 'post',
    url: '/api/cart/discount',
    headers: { 'X-CSRF-Token': accessToken },
    data: { uuid, code },
  })
    .then(({ data }) => {
      if (getState().cart.debug && console) { console.log(data) }

      // refresh cart with updated contents
      // after successful discount calculations
      if (data?.cart) {
        dispatch(loadCart({
          uuid: data.cart.uuid,
          contents: data.cart.contents,
          contains_a_meal_plan: data.contains_a_meal_plan,
          contains_subscriptions: data.contains_subscriptions,
          user_id: data.cart.user_id,
          calculated_amount_saved: data.cart.calculated_amount_saved,
          calculated_shipping_cost: data.cart.calculated_shipping_cost,
          calculated_service_fee: data.cart.calculated_service_fee,
          calculated_addons_cost: data.cart.calculated_addons_cost,
          calculated_total_before_savings: data.cart.calculated_total_before_savings,
          calculated_total_after_savings: data.cart.calculated_total_after_savings,
        }))
      }

      // always update discount code object
      if (data?.code) {
        dispatch(setDiscountCode({
          id: data?.code.id || null,
          expires_at: data?.code.expires_at || null,
          code: data?.code.code || null,
          message: data.message,
        }))
      } else {
        dispatch(setDiscountCode({
          message: data.message,
        }))
      }
    })
    .catch((error) => {
      if (getState().cart.debug && console) { console.log(error.response) }

      // if a 404 is returned, then the cart no longer exists
      // so clear the cart UUID from the visitor's localStorage
      // and reset the visitor's cart by creating a new one
      // with the existing contents WITHOUT any applied discounts
      if (error.response.status === 404) {
        dispatch(clearCart())
        dispatch(updateOrCreateCart())
        alert('Something happened on our end, please try again.')
      }
    })
    .finally(() => {
      dispatch(setLoading(false))
    })
}

export default cartSlice.reducer

