/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

export default function IconOption({
  id,
  content,
  previousAnswer,
  medicalTopicList,
}) {
  const iconName = `icon-${medicalTopicList[0]?.toLowerCase()?.replace(' ', '-')}`

  return (
    <div key={id} className="col-sm-4 mb-4 d-flex align-items-stretch">
      <label className="card-as-button">
        <input
          type="radio"
          name="main_concern"
          defaultChecked={previousAnswer === String(id)}
          value={id}
        />
        <div className="card card-bordered text-center">
          <div className="icon-as-graphic">
            <i className={iconName} />
          </div>
          <div className="card-content">
            <h4 className="label">{content}</h4>
          </div>
        </div>
      </label>
    </div>
  )
}

IconOption.propTypes = {
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  medicalTopicList: PropTypes.arrayOf(PropTypes.string).isRequired,
}
