import { createSlice } from '@reduxjs/toolkit'

export const recaptchaSlice = createSlice({
  name: 'recaptcha',
  initialState: {
    verified: process.env.RAILS_ENV === 'test' ? true : null,
  },
  reducers: {
    setVerified: (state, action) => {
      state.verified = action.payload
    },
  },
})

export const {
  setVerified,
} = recaptchaSlice.actions

export const selectVerified = (state) => state.recaptcha.verified

export default recaptchaSlice.reducer
