import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formattedNumber } from '@/shared/helpers/String'
import {
  loadCart,
  fetchCart,
  updateOrCreateCart,
  setOpen,
  setStatus,
  selectContents,
  selectLoaded,
  selectOpen,
  selectQuantity,
  selectStatus,
  selectUUID,
} from '@/public/redux/cartSlice'

export default function CartButton({
  uuid = null,
  user_id = null,
  contents: rawContents = [],
  discount_code = null,
  calculated_amount_saved = null,
  calculated_shipping_cost = null,
  calculated_service_fee = null,
  calculated_total_before_savings = null,
  calculated_total_after_savings = null,
  contains_subscriptions = null,
  mobile = false,
  debug = false,
}) {
  const dispatch = useDispatch()
  const loaded = useSelector(selectLoaded)
  const status = useSelector(selectStatus)
  const cartId = useSelector(selectUUID)
  const open = useSelector(selectOpen)
  const contents = useSelector(selectContents)
  const quantity = useSelector(selectQuantity)

  const handleOpen = (e) => {
    e.stopPropagation()

    if (quantity > 0) {
      dispatch(setOpen(true))
    }
  }

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      dispatch(setOpen(false))
    }
  }

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    if (!loaded) {
      if (!mobile) {
        // If the rails side doesn't know about an existing cart
        // but the visitor's browser does, go and fetch it
        if (!uuid && window.localStorage.getItem('cart')) {
          dispatch(fetchCart())

        // Otherwise, initialize the cart with whatever values
        // are passed in from the rails side. These could be either
        // empty values or an existing cart that is tied to the
        // currently logged in user (if one exists)
        //
        // NOTE: if initialized with empty values, a new cart record
        // will be created upon the first addition to the cart and
        // the UUID saved to the visitor's localStorage for later
        } else {
          dispatch(loadCart({
            uuid,
            user_id,
            contents: rawContents,
            discount_code,
            calculated_amount_saved,
            calculated_shipping_cost,
            calculated_service_fee,
            calculated_total_before_savings,
            calculated_total_after_savings,
            contains_subscriptions,
          }))
        }
      }

      document.addEventListener('keydown', handleEscape, false)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])

  /**
   * Store cart via API
   */
  useEffect(() => {
    if (!loaded || !contents || mobile) { return }

    if (debug && console) { console.log(`${status} cart`) }

    switch (true) {
      case /(creating|updating)/i.test(status):
        dispatch(updateOrCreateCart())
        break

      case /needs-merged/.test(status):
        dispatch(fetchCart({ merge: true }))
        break
    }
  }, [status])

  return (
    <button
      type="button"
      style={{ cursor: quantity > 0 ? 'pointer' : 'initial' }}
      onClick={(e) => handleOpen(e)}
    >
      <i className="fs-4 icon-cart" />

      {
        quantity > 0 ? (
          <span className="badge rounded-pill">
            {
              !/(needs-merged)/.test(status) || !loaded
                ? formattedNumber(quantity)
                : ''
            }
          </span>
        ) : (
          <span className="badge rounded-pill bg-none">
            &nbsp; &nbsp;
          </span>
        )
      }
    </button>
  )
}
