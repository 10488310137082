import React, { useState } from 'react'
import uuid from 'react-uuid'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function SelectField({
  definition, data, component_order, element_order,
}) {
  const dispatch = useDispatch()
  const collection = definition?.collection || []
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const [currentItem, setCurrentItem] = useState(data?.record_id || '')

  const changeHandler = (e) => {
    setCurrentItem(e.target.value)
    dispatch(setHasBeenModified())
  }

  const genOption = (item) => {
    const [value, name] = item
    return <option key={uuid()} value={value}>{name}</option>
  }

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label
        className="col-sm-2 col-form-label"
        htmlFor={`${idBase}_${definition.name}`}
      >
        {definition.label}
      </label>

      <div className="col-auto">
        <select
          className="form-select"
          value={currentItem}
          onChange={changeHandler}
          name={`${fieldNameBase}[record_id]`}
        >
          {collection.map((item) => genOption(item))}
        </select>
      </div>
    </div>
  )
}
