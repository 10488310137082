// NON-EXPORTED HELPERS ONLY ----------------------------------------------------------------

// convert the given variable first to a JSON object
// (so that literally anything passed in becomes encodable)
// and then Base64 encode it so that we can simply compare
// encoded strings
const comparable = (variable) => btoa(JSON.stringify(variable))

// EXPORTS BELOW ----------------------------------------------------------------------------

export const indexOfItemInCart = (contents, item) => {
  let index = 0

  // the JSON copying/hack is necessary in order to
  // have a truly deep copy of the cart content object
  JSON.parse(JSON.stringify(contents))?.forEach((_item, _index) => {
    if (_item.data.sku === item.data.sku && _item?.subscription === item?.subscription) {
      index = _index
    }
  })

  return index
}

// add or remove items from a given array (assumed to be an array in state) this will
// directly compare the item with items in the array and will even DEEP compare by
// converting everything it tries to compare to a Base64 encoded string prior to
// comparison (i.e, it is only comparing encoded representative strings)
export const addOrRemove = (item, array) => {
  const comparableItem = comparable(item)
  const comparableArray = [...array].map((item) => comparable(item))

  return comparableArray.includes(comparableItem)
    ? removeItem(item, array, comparableItem)
    : addItem(item, array)
}

export const addItem = (item, array) => [...array, item]

export const removeItem = (item, array, preComparableItem = null) => {
  const target = preComparableItem !== null ? preComparableItem : comparable(item)
  return [...array].filter((i) => comparable(i) !== target)
}
