import React, { useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import axios from 'axios'
import { accessToken } from '@/shared/helpers/CSRF'
import ccLogos from '../../../images/cc.png'

export default function UpdatePaymentMethodForm({
  debug = false,
  user = null,
}) {
  const [cardError, setCardError] = useState(null)
  const [displayFlash, setDisplayFlash] = useState(false)
  const [flash, setFlash] = useState({})

  const stripe = useStripe()
  const elements = useElements()
  const inputStyles = {
    base: {
      fontSize: '20px',
      fontWeight: 400,
      color: '#3f3e36',
      '::placeholder': {
        color: 'transparent',
      },
    },
    invalid: {
      color: '#cb252d',
    },
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (debug && console) {
      console.log('validating card')
    }

    const cardNumberElement = elements.getElement('cardNumber')
    const pm = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: [user.first_name, user.last_name].join(' '),
        email: user.email,
        phone: user.phone,
        address: {
          line1: user.addresses.billing.line_1,
          line2: user.addresses.billing.line_2,
          city: user.addresses.billing.city,
          state: user.addresses.billing.state,
          postal_code: user.addresses.billing.zip,
          country: 'US',
        },
      },
    })

    if (debug && console) {
      console.log(pm)
    }

    if ('error' in pm && pm.error.type === 'validation_error') {
      setCardError(pm.error)
    } else {
      setCardError(null)
      axios({
        method: 'patch',
        url: '/api/users/update_default_payment_method',
        headers: { 'X-CSRF-Token': accessToken },
        data: {
          payment_method_id: pm.paymentMethod.id,
        },
      })
        .then((response) => {
          if (response.data.success) {
            setFlash({ message: 'Changes saved', className: 'success' })

            // redirect back to accounts if successful after a short delay
            window.setTimeout(() => { window.location.href = '/account' }, 3000)
          } else {
            setFlash({ message: 'Unknown error occurred. Update failed', className: 'danger' })
          }
          setDisplayFlash(true)
        })
    }

    return true
  }

  return (
    <>
      { displayFlash && (
      <div className={`text-center flash-alert alert alert-${flash.className} mt-5`} role="alert">
        {flash.message}
      </div>
      )}
      <img
        src={ccLogos}
        style={{ maxWidth: '350px' }}
        className="mt-5 mb-3"
        alt="Accepted cards include Mastercard, Visa, AMEX, and JCB"
      />
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row mb-3">
          <div className="col">
            <label
              className={`form-label ${
                /number/i.test(cardError?.code) ? 'error' : ''
              }`}
            >
              Card Number
            </label>

            <CardNumberElement
              className={`form-control ${
                /number/i.test(cardError?.code) ? 'error' : ''
              }`}
              options={{ style: inputStyles }}
            />

            {/number/i.test(cardError?.code) && (
              <em className="text-danger" style={{ fontSize: '90%' }}>
                {cardError.message}
              </em>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <label
              className={`form-label ${
                /expiry/i.test(cardError?.code) ? 'error' : ''
              }`}
            >
              MM/YY
            </label>

            <CardExpiryElement
              className={`form-control ${
                /expiry/i.test(cardError?.code) ? 'error' : ''
              }`}
              options={{ style: inputStyles }}
            />

            {/expiry/i.test(cardError?.code) && (
              <em className="text-danger" style={{ fontSize: '90%' }}>
                {cardError.message}
              </em>
            )}
          </div>
          <div className="col-6">
            <label
              className={`form-label ${
                /cvc/i.test(cardError?.code) ? 'error' : ''
              }`}
            >
              CVV
            </label>

            <CardCvcElement
              className={`form-control ${
                /cvc/i.test(cardError?.code) ? 'error' : ''
              }`}
              options={{ style: inputStyles }}
            />

            {/cvc/i.test(cardError?.code) && (
              <em className="text-danger" style={{ fontSize: '90%' }}>
                {cardError.message}
              </em>
            )}
          </div>
          <div className="button-bar text-right border-top mt-5 pt-3">
            <button disabled={!stripe} className="btn btn-primary px-4">
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
