function copyToClipboard(e) {
  const trigger = e.currentTarget;
  const target = document.getElementById(trigger.dataset.target)
  navigator.clipboard.writeText(target.innerHTML);
  // eslint-disable-next-line no-new, no-undef
  const tooltip = new bootstrap.Tooltip(trigger, { trigger: 'manual' })
  tooltip.show()
  setTimeout(() => tooltip.hide(), 1000)
}

$(() => {
  const triggers = document.querySelectorAll('.copy-trigger')
  triggers.forEach((trigger) => trigger.addEventListener('click', copyToClipboard))
})
