import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHasBeenModified, selectAvailableIcons } from '@/admin/components/redux/componentsSlice'

export default function IconField({
  definition,
  data,
  component_order,
  element_order,
}) {
  const dispatch = useDispatch()
  const available_icons = useSelector(selectAvailableIcons)
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const [currentIcon, setCurrentIcon] = useState(data?.content || '')

  const changeHandler = (e) => {
    setCurrentIcon(e.target.value)
    dispatch(setHasBeenModified())
  }

  const genIconButton = (iconName, index) => (
    <span key={index}>
      <input
        type="radio"
        className="btn-check"
        autoComplete="off"
        onChange={changeHandler}
        value={iconName}
        name={`${fieldNameBase}[content]`}
        checked={iconName === currentIcon}
        id={`${iconName}_${element_order}`}
      />
      <label
        className="btn btn-sm btn-outline-secondary mb-2 me-2"
        htmlFor={`${iconName}_${element_order}`}
      >
        <i className={`icon-${iconName} fs-3`} />
        <br />
        {iconName}
      </label>
    </span>
  )

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label className="col-sm-2 col-form-label" htmlFor={`${idBase}_${definition.name}`}>
        {definition.label}
      </label>
      <div className="col-sm-10">
        {available_icons.map((icon, index) => genIconButton(icon, index))}
      </div>
    </div>
  )
}
