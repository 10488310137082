import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    content: null,
  },
  reducers: {
    setContent: (state, action) => {
      state.content = action.payload
    },
  },
})

export const { setContent } = modalSlice.actions

export const selectContent = (state) => state.modal.content

export default modalSlice.reducer
