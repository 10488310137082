import React from 'react'
import { useSelector } from 'react-redux'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import { selectPaymentRequestOpen } from '@/public/redux/cartSlice'

export default function QuantityInput({ quantity = 1, handleChange }) {
  const verified = useSelector(selectVerified)
  const paymentRequestOpen = useSelector(selectPaymentRequestOpen)

  const handleDecrement = () => {
    if (verified) {
      const newQuantity = quantity > 1 ? quantity - 1 : 1
      handleChange(newQuantity)
    }
  }

  const handleIncrement = () => {
    if (verified) {
      const newQuantity = quantity + 1
      handleChange(newQuantity)
    }
  }

  return (
    <div className="incrementor">
      <div
        className={`minus ${paymentRequestOpen ? 'payment-request-open' : ''}`}
        onClick={handleDecrement}
      >
        <i className="icon-minus" />
      </div>

      <input
        type="text"
        value={quantity}
        disabled={!verified}
        onChange={(e) => handleChange(Number(e.target.value))}
      />

      <div
        className={`plus ${paymentRequestOpen ? 'payment-request-open' : ''}`}
        onClick={handleIncrement}
      >
        <i className="icon-plus" />
      </div>
    </div>
  )
}
