function activateThumbnails() {
  document.querySelectorAll('.zoomed-carousel .scroll-container').forEach((scrollContainer) => {
    scrollContainer.closest('.zoomed-carousel').querySelectorAll('.thumbnail').forEach((thumbnail) => {
      const index = thumbnail.dataset.index
      thumbnail.addEventListener('click', () => {
        // eslint-disable-next-line no-param-reassign
        scrollContainer.scrollLeft = index * window.root__zoomedCarouselItemWidth
        scrollContainer.focus()
      })
    })
  })
}

function findItemWidth() {
  const firstCarouselItem = document.querySelector('.zoomed-carousel .item')
  if (!firstCarouselItem) {
    return 0
  }
  return firstCarouselItem.scrollWidth
}

function focusItemOnModalOpen(modal) {
  modal.addEventListener('shown.bs.modal', () => {
    modal.querySelector('.scroll-container').focus()
  })
}

function activateKeyboardInteraction(modal) {
  const scrollContainer = modal.querySelector('.scroll-container')
  const moveCarousel = (event) => {
    if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
      return
    }
    event.preventDefault()

    if (event.key === 'ArrowRight') {
      scrollContainer.scrollLeft += window.root__zoomedCarouselItemWidth
    } else if (event.key === 'ArrowLeft') {
      scrollContainer.scrollLeft -= window.root__zoomedCarouselItemWidth
    }
  }

  modal.addEventListener('show.bs.modal', () => {
    scrollContainer.addEventListener('keydown', moveCarousel)
  })
  modal.addEventListener('hide.bs.modal', () => {
    scrollContainer.removeEventListener('keydown', moveCarousel)
  })
}

function addModalEvents() {
  document.querySelectorAll('.modal:has(.zoomed-carousel)').forEach((modal) => {
    focusItemOnModalOpen(modal)
    activateKeyboardInteraction(modal)
  })
}

/**
 * If there is a product carousel associated with the zoomed carousel, make sure that
 * clicking on an item will zoom into the same item on the zoomed carousel.
 */
function syncProductCarousel() {
  document.querySelectorAll('.product-carousel-item').forEach((item) => {
    item.addEventListener('click', () => {
      const carousel = item.closest('.product-carousel')
      if (!carousel || !carousel.dataset.zoomTarget) {
        return
      }
      const scrollContainer = document.getElementById(carousel.dataset.zoomTarget).querySelector('.scroll-container')
      scrollContainer.scrollLeft = item.dataset.index * window.root__zoomedCarouselItemWidth
    })
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const itemWidth = findItemWidth()
  if (itemWidth === 0) {
    return
  }
  window.root__zoomedCarouselItemWidth = itemWidth

  activateThumbnails()
  addModalEvents()
  syncProductCarousel()
})

window.addEventListener('resize', () => {
  const itemWidth = findItemWidth()
  if (itemWidth === 0) {
    return
  }
  window.root__zoomedCarouselItemWidth = itemWidth
})
