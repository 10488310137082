import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  addToCart, isItemInCart, selectStatus, setOpen,
} from '@/public/redux/cartSlice'

export default function SubscribeButton({
  product,
  text = 'Subscribe',
  className = 'btn btn-subscription',
  children,
}) {
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const cartStatus = useSelector(selectStatus)

  const shouldDisable = !verified
    || /updating/i.test(cartStatus)
    || /back_ordered/i.test(product.status)

  const isSubscriptionInCart = useSelector(
    (state) => isItemInCart(state, { data: { ...product }, subscription: true }).answer,
  )

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(setOpen(true))
    dispatch(addToCart({
      data: {
        ...product,
        ...{ price: product.subscription_price },
      },
      quantity: 1,
      subscription: true,
    }))
  }

  return (
    <button
      type="button"
      className={`${className}${isSubscriptionInCart ? ' disabled' : ''}`}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      {children || text}
    </button>
  )
}
