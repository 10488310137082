import React from 'react'

export default function PolicyLinks({
  product, shippingPolicy, returnPolicy, plan_terms,
}) {
  return (
    <div className="helpers">
      {product.category === 'consultation_program' ? (
        <>
          {plan_terms
            && (
              <button
                type="button"
                className="btn btn-link d-inline-block me-4 text-capitalize mt-2 mt-md-4"
                data-bs-toggle="modal"
                data-bs-target={`#${plan_terms.permalink}`}
              >
                Plan Terms
              </button>
            )}
        </>
      )
        : (
          <>
            {shippingPolicy && product.is_shippable
              && (
                <button
                  type="button"
                  className="btn btn-link d-inline-block me-4 text-capitalize mt-2 mt-md-4"
                  data-bs-toggle="modal"
                  data-bs-target={`#${shippingPolicy.permalink}`}
                >
                  {shippingPolicy.title.replace(/(\s)?policy/i, '')}
                  {' '}
                  Policy
                </button>
              )}

            {returnPolicy
              && (
                <button
                  type="button"
                  className="btn btn-link d-inline-block me-4 mt-2 mt-md-4"
                  data-bs-toggle="modal"
                  data-bs-target={`#${returnPolicy.permalink}`}
                >
                  {returnPolicy.title.replace(/(\s)?policy/i, '')}
                  {' '}
                  Policy
                </button>
              )}
          </>
        )}
    </div>
  )
}
