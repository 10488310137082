import React from 'react'
import utils from '@/shared/WizardUtils'
import Button from '@/public/wizards/Button'
import store from '@/public/redux/store'

export default function Transition({
  globalWizardSpecificActions, debug, onClick = () => {}, ...props
}) {
  const {
    subtitle, text, text_align = 'left', actions, render,
  } = props.data

  const handleClick = () => {
    utils.executeActions(actions, globalWizardSpecificActions, debug)
  }

  let dynamic_section = null
  if (typeof render === 'function') {
    dynamic_section = render(store.getState())
  }

  return (
    <div className="assessment container d-flex justify-content-center">
      <div className="row justify-content-center align-items-center w-100">
        <div className={`col-md-10 col-lg-8 mb-5 pb-5 text-${text_align}`}>

          { subtitle
                  && (
                  <div className="h6 neutral1-d30">
                    { subtitle }
                  </div>
                  )}

          { text
                  && (
                  <h2 className="h3 sm mt-3 mb-5">
                    { text }
                  </h2>
                  )}

          {dynamic_section}

          <Button onClick={handleClick} />

        </div>
      </div>
    </div>
  )
}
