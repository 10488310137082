import React from 'react'
import { numberToCurrency } from '@/shared/helpers/String'
import AddToCartButton from '@/public/product/AddToCartButton'
import ApplicationButton from '@/public/product/ApplicationButton'
import PolicyLinks from '@/public/product/PolicyLinks'

export default function PriceBlockWithoutQuantity({
  product,
  return_policy,
  shipping_policy,
  plan_terms,
  debug = false,
}) {
  return (
    <div className="card">
      <div className="card-content pt-4">
        <div className="subtitle bold">
          {numberToCurrency(product.price)}
        </div>

        <div className="actions mt-3">
          {product.is_applicable
            && (
              <ApplicationButton
                product={product}
                button_text={product.button_text}
                product_url={product.application_url}
                target="_blank"
                className="btn btn-primary btn-block"
              />
            )}

          {product.is_purchasable
            && <AddToCartButton product={product} className="btn btn-primary btn-block" />}

          <PolicyLinks
            product={product}
            shippingPolicy={shipping_policy}
            returnPolicy={return_policy}
            plan_terms={plan_terms}
          />
        </div>
      </div>
    </div>
  )
}
