import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function CheckboxField({
  definition, data, component_order, element_order,
}) {
  const dispatch = useDispatch()
  const collection = definition?.collection || []
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const [checkedBoxes, setCheckedBoxes] = useState(data?.record_ids?.map((ele) => parseInt(ele)) || [])

  const changeHandler = (item, checked) => {
    if (checked) {
      setCheckedBoxes(checkedBoxes.filter((ele) => ele !== item))
    } else {
      setCheckedBoxes([...checkedBoxes, item])
    }
    dispatch(setHasBeenModified())
  }

  const genInputs = (item, index) => {
    const checked = checkedBoxes.includes(item[0])

    return (
      <div key={index} className="d-inline-block">
        {definition.switch ? (
          <div className="form-check form-switch">
            <input
              type="checkbox"
              id={`${fieldNameBase}_${item[0]}_${element_order}`}
              name={`${fieldNameBase}[record_ids][]`}
              checked={checked}
              onChange={() => changeHandler(item[0], checked)}
              className="form-check-input"
              value={item[0]}
            />
            <label
              htmlFor={`${fieldNameBase}_${item[0]}_${element_order}`}
              className="form-check-label text-capitalize"
            >
              {item[1]}
            </label>
          </div>
        ) : (
          <>
            <input
              type="checkbox"
              id={`${fieldNameBase}_${item[0]}_${element_order}`}
              name={`${fieldNameBase}[record_ids][]`}
              checked={checked}
              onChange={() => changeHandler(item[0], checked)}
              className="form-check-input"
              value={item[0]}
            />
            <label
              htmlFor={`${fieldNameBase}_${item[0]}_${element_order}`}
              className="mb-2 ms-2 me-4 text-capitalize"
            >
              {item[1]}
            </label>
          </>
        )}
      </div>
    )
  }

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label className="col-sm-2 form-check-label" htmlFor={`${idBase}_${definition.name}`}>
        {definition.label}
      </label>
      <div className="col-sm-10">
        {collection.map((id, index) => genInputs(id, index))}
      </div>
    </div>
  )
}
