import React from 'react'

export default function Tag({ text = '', handleRemoveTag }) {
  return (
    <button
      type="button"
      className="btn btn-sm bg-info align-middle text-white mb-2 me-2"
      value={text}
      onClick={(e) => handleRemoveTag(e.currentTarget.value)}
    >
      { text }
      <i className="bi bi-x-circle-fill ms-2" />
    </button>
  )
}
