import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function SwitchField({
  definition,
  data,
  component_order,
  element_order,
}) {
  const dispatch = useDispatch()
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const [checked, setChecked] = useState((data?.boolean === 'true') || false)

  const changeHandler = () => {
    setChecked(!checked)
    dispatch(setHasBeenModified())
  }

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <div className="col-sm-2" />

      <div className="col-auto">
        <div className="form-check form-switch">
          <input
            type="checkbox"
            id={`${idBase}_${definition.name}`}
            name={`${fieldNameBase}[boolean]`}
            checked={checked}
            onChange={changeHandler}
            className="form-check-input"
            value={checked}
          />
          <label
            className="form-check-label text-capitalize"
            htmlFor={`${idBase}_${definition.name}`}
          >
            {definition.label}
          </label>
        </div>
      </div>
    </div>
  )
}
