const customerSignedIn = (window.localStorage.getItem('customer-signed-in') === 'true')
const customerIsMealPlanEligible = (window.localStorage.getItem('customer-is-meal-plan-eligible') === 'true')
const customerTimezonePreference = window.localStorage.getItem('customer-timezone-preference')
// NOTE: the existence of an email does not necessarily mean the customer is signed in
const customerEmail = window.localStorage.getItem('customer-email')

export {
  customerSignedIn,
  customerIsMealPlanEligible,
  customerTimezonePreference,
  customerEmail,
}
