/* eslint-disable no-underscore-dangle */
import * as bootstrap from 'bootstrap'

const $document = jQuery(document)
const $window = jQuery(window)

$(($) => {
  /**
   * -----------------------------------------------------------------------------------
   * Root Mobile nav
   * -----------------------------------------------------------------------------------
   */
  $('.mobile-toggle').on('click', (e) => {
    $(e.currentTarget).closest('.mobile-nav').toggleClass('open')
    $(e.currentTarget).toggleClass('open')
  })

  /**
   * -----------------------------------------------------------------------------------
   * Root Dropdown Menu
   * -----------------------------------------------------------------------------------
   */
  const btnRootDropdowns = document.getElementsByClassName('btn-root-dropdown')

  // open
  $('.btn-root-dropdown-label').on('click', (e) => {
    $(e.currentTarget).parent('.btn-root-dropdown').addClass('open')
  })

  // close
  $document.on('mouseup', (e) => {
    if (!$('.root-select-ui .btn-root-dropdown-label').is(e.target)) {
      $(btnRootDropdowns).removeClass('open')
    }
  })

  // auto-adjust widths
  Array.from(btnRootDropdowns).forEach((dropdown) => {
    const $this = $(dropdown)
    const dropWidth = $this.find('ul').width()
    const btnWidth = $this.width()

    if (btnWidth >= dropWidth) {
      $this.find('ul').width(btnWidth)
    } else {
      $this.width(dropWidth)
    }
  })

  /**
   * -----------------------------------------------------------------------------------
   * Form Elements
   * -----------------------------------------------------------------------------------
   */
  $('.form-check-emphasize .form-check-input').on('change', (e) => {
    const $this = $(e.currentTarget)
    const $parent = $this.parent('.form-check-emphasize')
    $parent[$this.is(':checked') ? 'addClass' : 'removeClass']('checked')
  })

  $('select:not(.react, .default-select)').customSelect()

  const scrollToInvalidFormControls = () => {
    const invalidControl = $('.form-control.is-invalid').first()[0]
    if (invalidControl) {
      invalidControl.scrollIntoView()
    }
  }

  const scrollToSubmitSuccess = () => {
    const submitSuccess = $('.submit-success').first()[0]
    if (submitSuccess) {
      submitSuccess.scrollIntoView()
    }
  }

  /**
   * -----------------------------------------------------------------------------------
   * Scrolling animations
   * -----------------------------------------------------------------------------------
   */
  const fadeAnimations = document.getElementsByClassName('fade-anim')

  const inViewport = () => {
    Array.from(fadeAnimations).forEach((animation) => {
      const $this = $(animation)
      let topOffset = $this.data('offset')

      if (!topOffset) {
        topOffset = 0.5
      }

      const divPos = $this.offset().top
      const topOfWindow = $window.scrollTop() + ($window.height() * topOffset)

      if (divPos < topOfWindow) {
        $this.addClass('go')
        $this.removeClass('hide')
      }
    })
  }

  // fade-up animation
  $(fadeAnimations).addClass('hide')

  /**
   * -----------------------------------------------------------------------------------
   * Store/Blog Category Filters
   * -----------------------------------------------------------------------------------
   */
  $('.filters-toggle').on('click', (e) => {
    $(e.currentTarget).toggleClass('btn-secondary-filled')
    $('.list-filters').toggleClass('show')
  })

  /**
   * -----------------------------------------------------------------------------------
   * Accordions
   * -----------------------------------------------------------------------------------
   */
  const $accordionTitles = $('.accordion-title')

  $accordionTitles.on('click', (e) => {
    // close the open on when another is selected
    $accordionTitles.not(e.currentTarget).removeClass('open')
    $(e.currentTarget).toggleClass('open')
  })

  /**
   * -----------------------------------------------------------------------------------
   * Meal Plan Builder/Selector
   * -----------------------------------------------------------------------------------
   */
  const $orderSummaryOverlay = $('.order-summary-overlay')

  $('.replace-item').on('click', () => {
    $orderSummaryOverlay.addClass('show')
  })

  $orderSummaryOverlay.find('.cancel').on('click', () => {
    $orderSummaryOverlay.removeClass('show')
  })

  /**
   * -----------------------------------------------------------------------------------
   * Product purchase utility mobile toggle
   * -----------------------------------------------------------------------------------
   */
  $('.mobile-product-utility-toggle').on('click', () => {
    $('.product-utility-actions').toggleClass('open')
  })

  $('.button_to').on('ajax:success', (e) => {
    const response = e.detail[0]
    const { review } = response
    const { vote } = response

    const buttons = e.target.parentElement

    buttons.querySelector('.upvotes-count').innerText = review.upvotes_count
    buttons.querySelector('.downvotes-count').innerText = review.downvotes_count

    const upvote = buttons.querySelector('.upvote')
    const downvote = buttons.querySelector('.downvote')

    if (vote) {
      if (vote.helpful) {
        upvote.classList.remove('icon-button-grey')
        upvote.classList.add('icon-button-black')
        downvote.classList.remove('icon-button-black')
        downvote.classList.add('icon-button-grey')
      } else {
        upvote.classList.remove('icon-button-black')
        upvote.classList.add('icon-button-grey')
        downvote.classList.remove('icon-button-grey')
        downvote.classList.add('icon-button-black')
      }
    } else {
      upvote.classList.remove('icon-button-black')
      upvote.classList.add('icon-button-grey')
      downvote.classList.remove('icon-button-black')
      downvote.classList.add('icon-button-grey')
    }
  })

  /**
   * -----------------------------------------------------------------------------------
   * Carousels
   * -----------------------------------------------------------------------------------
   */
  // Docs on this specific event: https://getbootstrap.com/docs/5.0/components/carousel/#events
  $('.carousel').each((_, carousel) => {
    carousel.addEventListener('slide.bs.carousel', (e) => $(carousel).find('.slider-number').text(e.to + 1))
  })
  const normalizeCarouselHeights = () => {
    $('.carousel-normalize-height .carousel').each((_idxCarousel, carousel) => {
      const items = $(carousel).find('.carousel-inner .carousel-item')
      const tallest = items.toArray().reduce((accumulator, item) => (
        Math.max($(item).height(), accumulator)
      ), 0)
      items.each((_idxItem, item) => $(item).find('.card').css('min-height', `${tallest}px`))
    })
  }

  // Hide modals with escape key.
  // TODO: Figure out why this isn't working out of the box with bootstrap
  $('.modal').each((_, modal) => {
    const bootstrapModal = bootstrap.Modal.getOrCreateInstance(modal, { keyboard: true })
    const closeModal = (e) => {
      if (e.key === 'Escape') {
        bootstrapModal.hide()
      }
    }
    modal.addEventListener('show.bs.modal', () => {
      window.addEventListener('keydown', closeModal)
    })
    modal.addEventListener('hide.bs.modal', () => {
      window.removeEventListener('keydown', closeModal)
    })

    if (modal.classList.contains('loadmodal')) {
      bootstrapModal.show()
      modal.addEventListener('shown.bs.modal', scrollToInvalidFormControls)
    }
  })

  // For the product thumbnails inside a modal, focus the first thumbnail when the modal is shown
  // so that the arrow keys will still work
  $('.modal:has(.carousel):has(.product-thumbnails)').each((_, modal) => {
    modal.addEventListener('shown.bs.modal', () => {
      $(modal).find('.product-thumbnails button:first-child').trigger('focus')
    })
  })

  normalizeCarouselHeights()
  scrollToInvalidFormControls()
  scrollToSubmitSuccess()

  $window.on('scroll', () => { inViewport() })
  $window.on('resize', () => {
    inViewport()
    normalizeCarouselHeights()
  })
})

