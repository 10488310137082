import React from 'react'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function MultiSelectField({
  definition, data, component_order, element_order, size = 5,
}) {
  const dispatch = useDispatch()
  const collection = definition?.collection || []
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const genOptions = (item, index) => {
    const [value, name] = item
    return (
      <option key={index} value={value}>{name}</option>
    )
  }

  return (
    <div className="mb-3 row">
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <label className="col-sm-2 form-check-label" htmlFor={`${idBase}_${definition.name}`}>
        {definition.label}
      </label>
      <div className="col-auto">
        <select
          className="form-select"
          name={`${fieldNameBase}[record_ids][]`}
          id={`${idBase}_${definition.name}`}
          defaultValue={data?.record_ids || []}
          onChange={() => dispatch(setHasBeenModified())}
          multiple
          size={size}
        >
          {collection.map((id, index) => genOptions(id, index))}
        </select>
        <p className="fs-tiny text-muted mt-1 mb-0 text-center">Use CMD/ALT-Click to select multiple products</p>
      </div>
    </div>
  )
}
