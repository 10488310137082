/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Option from './Option'
import IconOption from './IconOption'
import Textbox from './Textbox'
import StateDropdown from './StateDropdown'

export default function Step({
  step: {
    id,
    content,
    options,
    option_type: type,
  },
  handleContinue,
  previousAnswer,
}) {
  function handleSubmit(e) {
    e.preventDefault()

    const formData = new FormData(e.target)
    const multipleSelections = []
    let answer = null

    for (const [name, value] of formData) {
      if (name === 'multiple_select') {
        multipleSelections.push(value)
        answer = multipleSelections
      } else {
        answer = value
      }
    }

    e.target.reset()

    handleContinue(answer)
  }

  const contentClass = (content.length > '80' ? 'long-header' : 'short-header')
  const containerType = (type === 'main_concern' ? 'assessments-container-wide' : 'assessments-container')

  return (
    <section className={`container ${containerType} pt-5`}>
      <div className="pt-5 mb-5">
        <h1 className={contentClass}>{content}</h1>
        {type === 'multiple_select' && (
          <p className="h5">(Select all that apply)</p>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {type === 'main_concern' && (
          <div className="row justify-content-center">
            {options.map((option) => (
              <IconOption
                medicalTopicList={option.medical_topic_list}
                key={option.id}
                id={option.id}
                content={option.content}
                type={type}
                previousAnswer={previousAnswer}
              />
            ))}
          </div>
        )}
        {(type === 'single_select' || type === 'multiple_select') && (
          options.map((option) => (
            <Option
              key={option.id}
              id={option.id}
              content={option.content}
              type={type}
              previousAnswer={previousAnswer}
            />
          ))
        )}
        {['text', 'email', 'name'].includes(type) && <Textbox stepId={id} type={type} previousAnswer={previousAnswer} />}
        {type === 'state' && <StateDropdown stepId={id} type={type} />}
        <div className="text-center mt-5">
          <button className="btn btn-primary assessment-btn" type="submit">
            {type !== 'end' ? 'Continue' : 'Get to the Root!'}
          </button>
        </div>
      </form>
    </section>
  )
}

Step.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    option_type: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  handleContinue: PropTypes.func.isRequired,
}
