/* eslint-disable no-param-reassign */

const NUMBER_OF_TABS = 6
const MAX_CONTENT_WIDTH = 850
const XL_BREAKPOINT = 1200

/**
 * @param {HTMLElement} accordion
 * @param {number} height
 */
function setContentHeight(accordion, height) {
  accordion.querySelectorAll('.month-content').forEach((content) => {
    content.style.height = `${height}px`
  })
}

/**
 * @param {HTMLElement} accordion
 * @param {number} width
 * @returns {number}
 */
function getTallestContentHeight(accordion, width) {
  let tallestContent = 0
  accordion.querySelectorAll('.month-content').forEach((content) => {
    // reset the height in case the screen size changed
    content.style.height = 'auto'
    // set the width so the height can be calculated correctly
    content.style.width = `${width}px`
    tallestContent = Math.max(content.offsetHeight, tallestContent)
  })
  return tallestContent
}

/** @param {HTMLElement} accordion */
function resetToMobileStyles(accordion) {
  accordion.style.width = 'unset'
  accordion.style.height = 'unset'
  accordion.querySelectorAll('li:has(input[type=radio])').forEach((tab) => {
    tab.style.width = 'unset'
  })
  accordion.querySelectorAll('.month-content').forEach((content) => {
    content.style.width = 'unset'
    content.style.height = 'unset'
  })
}

/**
 * @param {HTMLElement} accordion
 * @returns {number}
 */
function getTabWidth(accordion) {
  return Number(getComputedStyle(accordion).getPropertyValue('--tab-width-xl').split('px')[0])
}

/**
 * @param {HTMLElement} accordion
 * @returns {number}
 */
function getContentWidth(accordion) {
  const tabWidth = getTabWidth(accordion)
  const containerWidth = accordion.closest('div').offsetWidth
  return Math.min(containerWidth - (NUMBER_OF_TABS * tabWidth), MAX_CONTENT_WIDTH)
}

document.addEventListener('DOMContentLoaded', () => {
  const accordion = document.getElementById('how-it-works-accordion')
  if (!accordion) {
    return
  }

  const setAccordionDimensions = () => {
    const tabWidth = getTabWidth(accordion)
    const contentWidth = getContentWidth(accordion)
    const openTab = accordion.querySelector('li:has(input[type=radio]:checked)')

    if (window.innerWidth < XL_BREAKPOINT) {
      resetToMobileStyles(accordion)
      return
    }

    openTab.style.width = `${contentWidth + tabWidth}px`
    accordion.style.width = `${tabWidth * NUMBER_OF_TABS + contentWidth}px`

    // statically set the content widths and height so the content doesn't shift
    // when the width of the tab changes
    const tallestHeight = getTallestContentHeight(accordion, contentWidth)
    setContentHeight(accordion, tallestHeight)
    accordion.style.height = `${tallestHeight}px`
  }

  setAccordionDimensions()

  accordion.querySelectorAll('li:has(input[type=radio])').forEach((tab) => {
    tab.addEventListener('change', () => {
      if (window.innerWidth < XL_BREAKPOINT) {
        return
      }

      const contentWidth = getContentWidth(accordion)
      const tabWidth = getTabWidth(accordion)
      // open the tab by expanding the width
      tab.style.width = tab.querySelector('input[type=radio]').checked
        ? `${contentWidth + tabWidth}px`
        : `${tabWidth}px`

      // reset other tabs
      accordion.querySelectorAll('li:has(input[type=radio]):not(:has(input[type=radio]:checked))').forEach((otherTab) => {
        otherTab.style.width = `${tabWidth}px`
      })
    })
  })

  window.addEventListener('resize', setAccordionDimensions)
})
