import React from 'react'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'

export default function DynamicField({
  definition, data, component_order, element_order,
}) {
  const dispatch = useDispatch()
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  return (
    <>
      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      <p className="mb-0 fs-tiny font-monospace text-muted">This component is not configurable.</p>
    </>
  )
}
