import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectVerified } from '@/public/redux/recaptchaSlice'
import {
  addToCart, selectStatus, setOpen, selectContents, isItemInCart,
} from '@/public/redux/cartSlice'

export default function AddToCartButton({
  product,
  quantity = 1,
  text = 'Add to Cart',
  className = 'btn btn-primary',
  disabled = false,
  children,
}) {
  const dispatch = useDispatch()
  const verified = useSelector(selectVerified)
  const cartStatus = useSelector(selectStatus)
  const cartContents = useSelector(selectContents)
  const alreadyInCart = useSelector((state) => isItemInCart(state, { data: { ...product } }).answer)
  const quantityLimited = alreadyInCart && !product.quantifiable

  const cartContentsSkus = cartContents === null ? [] : cartContents.map((item) => item.data.sku)

  const shouldDisable = !verified
    || quantityLimited
    || disabled
    || /updating/i.test(cartStatus)
    || /back_ordered/i.test(product.status)

  const handleClick = (e) => {
    e.stopPropagation()

    dispatch(setOpen(true))
    dispatch(addToCart({
      data: { ...product },
      quantity,
    }))
  }

  return (
    <button
      type="button"
      className={className}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      { children || text }
    </button>
  )
}
