import { createSlice } from '@reduxjs/toolkit'
import { accessToken } from '@/shared/helpers/CSRF'
import axios from 'axios'

export const assessmentSlice = createSlice({
  name: 'assessment',
  initialState: {
    questionsToAsk: [],
    questionResponses: [
    // {
    //   step_id: XXX,
    //   question: YYY,
    //   answers: ZZZ,
    // },
    ],
    recommendedProducts: [],
    medicalTopics: [],
    dietaryRestrictions: [],
    email: null,
    options: [],
    PCOS: {
      rootCauses: [],
      points: {
        insulin_resistance: [],
        adrenal: [],
        inflammation: [],
      },
    },
    isMealPlansEligible: null,
    additionalComments: null,
  },
  reducers: {
    setQuestionsToAsk: (state, action) => {
      state.questionsToAsk = action.payload
    },
    setQuestionResponses: (state, action) => {
      state.questionResponses = action.payload
    },
    setMedicalTopics: (state, action) => {
      state.medicalTopics = action.payload
    },
    setRecommendedProducts: (state, action) => {
      state.recommendedProducts = action.payload
    },
    setDietaryRestrictions: (state, action) => {
      state.dietaryRestrictions = action.payload
    },
    setVisitorEmail: (state, action) => {
      state.email = action.payload
    },
    setOptions: (state, action) => {
      state.options = action.payload
    },
    setRootCauses: (state, action) => {
      state.PCOS.rootCauses = action.payload
    },
    setPoints: (state, action) => {
      state.PCOS.points = action.payload
    },
    setIsMealPlansEligible: (state, action) => {
      state.isMealPlansEligible = action.payload
    },
    setAdditionalComments: (state, action) => {
      state.additionalComments = action.payload
    },
  },
})

export const {
  setQuestionsToAsk,
  setQuestionResponses,
  setMedicalTopics,
  setRecommendedProducts,
  setDietaryRestrictions,
  setVisitorEmail,
  setOptions,
  setRootCauses,
  setPoints,
  setIsMealPlansEligible,
  setAdditionalComments,
} = assessmentSlice.actions

export const selectMedicalTopics = (state) => state.assessment.medicalTopics
export const selectPoints = (state) => state.assessment.PCOS.points
export const selectQuestionResponses = (state) => state.assessment.questionResponses
export const selectQuestionsToAsk = (state) => state.assessment.questionsToAsk

export default assessmentSlice.reducer

export const storeAssessment = () => async (dispatch, getState) => {
  const { assessment } = getState()

  axios({
    method: 'post',
    url: '/api/assessment',
    headers: { 'X-CSRF-Token': accessToken },
    data: {
      assessment: {
        responses: assessment.questionResponses,
        recommended_products: assessment.recommendedProducts,
        medical_topic_list: assessment.medicalTopics,
        allergen_list: assessment.dietaryRestrictions,
        email: assessment.email,
        options: assessment.options,
        root_pcos_causes: assessment.PCOS.rootCauses,
        is_meal_plans_eligible: assessment.isMealPlansEligible,
        additional_comments: assessment.additionalComments,
      },
    },
  })
    .then(({ data }) => {
      if (getState().cart.debug && console) { console.log(data) }

      // redirect
      if (data?.result) { window.location = data.result }
    })
    .catch((error) => {
      if (getState().cart.debug && console) { console.log(error.response) }
    })
}
