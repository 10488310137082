import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { useDispatch } from 'react-redux'
import { setHasBeenModified } from '@/admin/components/redux/componentsSlice'
import IconSelector from '@/admin/components/IconSelector'

export default function RichTextField({
  definition, data, component_order, element_order,
}) {
  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)
  const [trix, setTrix] = useState(null)
  const [showIconSelector, setShowIconSelector] = useState(false)
  const [trixContent, setTrixContent] = useState(data?.content || '')

  const parentId = `trix-${uuid()}`
  const idBase = `components_content_${component_order}_elements_${element_order}`
  const fieldNameBase = `components[content][${component_order}][elements][${element_order}]`

  const handleActionButtonClick = (e) => {
    if (e.actionName === 'x-icon') {
      const button = document.getElementById(`${parentId}--icon-button`)

      if (button.classList.contains('trix-active')) {
        button.classList.remove('trix-active')
        setShowIconSelector(false)
      } else {
        button.classList.add('trix-active')
        setShowIconSelector(true)
      }
    }
  }

  /*
   * Mount/unmount
   */
  useEffect(() => {
    setTrix(document.getElementById(parentId))

    return () => {
      if (trix) {
        trix.removeEventListener('trix-initialize')
        trix.removeEventListener('trix-action-invoke')
        trix.removeEventListener('trix-file-accept')
        trix.removeEventListener('trix-change')
      }
    }
  }, [])

  /**
   * Configure the Trix editor
   */
  useEffect(() => {
    if (!trix) { return }

    window.Trix.config.textAttributes.icon = {
      inheritable: false,
      terminal: true,
      breakOnReturn: false,
      tagName: 'i',
    }
    window.Trix.config.blockAttributes.heading1 = { tagName: 'h1' }
    window.Trix.config.blockAttributes.heading2 = { tagName: 'h2' }
    window.Trix.config.blockAttributes.heading3 = { tagName: 'h3' }
    window.Trix.config.blockAttributes.heading4 = { tagName: 'h4' }
    window.Trix.config.blockAttributes.heading5 = { tagName: 'h5' }
    window.Trix.config.blockAttributes.heading6 = { tagName: 'h6' }

    trix.addEventListener('trix-initialize', (e) => {
      // insert additional header buttons
      trix.querySelector('.trix-button--icon-heading-1').insertAdjacentHTML(
        'afterend',
        '<button type="button" class="trix-button trix-button--heading-1 fs-6" data-trix-attribute="heading1" title="h1" tabindex="-1">H1</button>'
        + '<button type="button" class="trix-button trix-button--heading-2 fs-6" data-trix-attribute="heading2" title="h2" tabindex="-1">H2</button>'
        + '<button type="button" class="trix-button trix-button--heading-3 fs-6" data-trix-attribute="heading3" title="h3" tabindex="-1">H3</button>'
        + '<button type="button" class="trix-button trix-button--heading-4 fs-6" data-trix-attribute="heading4" title="h4" tabindex="-1">H4</button>'
        + '<button type="button" class="trix-button trix-button--heading-5 fs-6" data-trix-attribute="heading5" title="h5" tabindex="-1">H5</button>'
        + '<button type="button" class="trix-button trix-button--heading-6 fs-6" data-trix-attribute="heading6" title="h6" tabindex="-1">H6</button>',
      )

      // inject the new icon selection button into the Trix toolbar
      trix.querySelector('.trix-button--icon-number-list').insertAdjacentHTML(
        'afterend',
        `<button id="${parentId}--icon-button" type="button" class="trix-button" title="Insert Icon(s)" data-trix-action="x-icon">`
            + '<i class="bi bi-info-circle-fill fs-6 px-1"></i>'
        + '</button>',
      )

      // remove certain buttons
      trix.querySelector('.trix-button--icon-heading-1').remove()
      trix.querySelector('.trix-button--icon-quote').remove()
      trix.querySelector('.trix-button--icon-code').remove()

      // hijack click event of the button and show the react icon selector
      const button = document.getElementById(`${parentId}--icon-button`)
    })

    // prevent drag/drop file uploads
    trix.addEventListener('trix-file-accept', (e) => { e.preventDefault() })

    // handle clicking the icon selection button
    trix.addEventListener('trix-action-invoke', (e) => handleActionButtonClick(e))

    // set modified state if any trix editor's content ever changes
    trix.addEventListener('trix-change', (e) => {
      setTrixContent(e.target.value)
      dispatch(setHasBeenModified())
    })

    setLoaded(true)
  }, [trix])

  return (
    <div id={parentId} className={`${definition?.standalone ? '' : 'mt-5'} mb-3 row`}>
      <style type="text/css">
        {`
        .trix-button-group--file-tools {
          display: none !important;
        }
      `}
      </style>

      <input type="hidden" name={`${fieldNameBase}[name]`} defaultValue={definition.name || ''} />
      <input type="hidden" name={`${fieldNameBase}[type]`} defaultValue={definition.type} />

      { !definition?.standalone
        && (
        <label
          className="col-sm-2 col-form-label"
          htmlFor={`${idBase}_${definition.name}`}
        >
          {definition.label}
        </label>
        )}

      <div className={definition?.standalone ? '' : 'col-sm-10'}>
        <input
          type="hidden"
          id={`${idBase}_content`}
          name={`${fieldNameBase}[content]`}
          value={trixContent}
        />

        { showIconSelector && <IconSelector baseId={parentId} /> }

        { /* NOTE: React will NOT render the trix-editor markup so a
             normal "class" instead of "className" is necessary here! */ }
        { loaded && <trix-editor class="form-control" input={`${idBase}_content`} /> }
      </div>
    </div>
  )
}
